import { get } from 'lodash'
import { createSelector } from 'reselect'

import { getAllEntities, getEntity, getEntityStateField } from '../../utils'

export const getState = state => state.data
export const getStateOrganizations = state => state.data?.organizations

export const getOrganizations = getAllEntities('organizations')
export const getFirstOrganizations = createSelector(
  getStateOrganizations,
  organizations => {
    if (!organizations) return {}
    return organizations[Object.keys(organizations)[0]]
  },
)
export const getCurrentOrganization = getEntity('organizations')

export const getOrganizationIsGroup = createSelector(
  getOrganizations,
  organizations => organizations.filter(org => org.isGroup === true),
)
export const getFirstOrganization = createSelector(getState, state => {
  if (state.organizations)
    return state.organizations[Object.keys(state?.organizations)[0]]
  return {}
})

export const getOrganizationName = createSelector(
  getCurrentOrganization,
  organization => {
    return get(organization, 'name')
  },
)

export const getIsLoaded = getEntityStateField('organizations', 'isLoaded')
export const getIsLoading = getEntityStateField('organizations', 'isLoading')
export const getPaged = getEntityStateField('organizations', 'paged')
