import {
  getEmail,
  getJWT,
} from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  getRedirectFinalStep,
  getViewerType,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { BREAKPOINTS } from '@lyfta/components-theme'
import { breakpointsNumber } from '@lyfta/components-theme/src/themes/Main'
import { ButtonNew, Email2, Flex, Text, USER_TYPES } from '@lyfta/components-ui'
import { openInTeacherApp } from '@lyfta/components-ui/src/Services/Utils'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Heading1 } from '../../../../../Components/Headings'
import { Container, LiContainer, UlContainer } from '../../styles'

function SetupDone() {
  const expiryDate = new Date()
  expiryDate.setDate(expiryDate.getDate() + 15)
  const email = useSelector(getEmail)
  const redirect = useSelector(getRedirectFinalStep)
  const type = useSelector(getViewerType)
  const jwt = useSelector(getJWT)
  const { innerWidth: widthApp } = window
  const tabletWidth = breakpointsNumber[BREAKPOINTS.TABLET]

  // const formattedDate = `
  //   ${expiryDate.getDate()}
  //   ${expiryDate.toLocaleString('default', { month: 'long' })},
  //   ${expiryDate.getFullYear()}`

  useEffect(() => {
    document.title = 'Registration - Done'
  }, [])

  return (
    <Container>
      <Flex justifyContent="center" mb={[5, 7]}>
        <I18n i18nKey="signUp.done.allSetTitle" mb={0} tag={Heading1} />
      </Flex>
      <Text fontSize="10rem" textAlign="center">
        <Email2 />
      </Text>
      <Flex justifyContent="center" maxWidth={400} my={5}>
        {
          /* eslint-disable */
          widthApp > tabletWidth ? (
            <I18n
              textAlign="center"
              i18nKey={
                type === 'parents'
                  ? 'signUp.done.detailsParents'
                  : 'signUp.done.details'
              }
              options={{ email }}
              fontSize={4}
              lineHeight={3}
              color="slateGrey"
              tag={Text}
            />
          ) : type === USER_TYPES.parents ? (
            <UlContainer>
              <LiContainer>
                <I18n
                  textAlign="left"
                  i18nKey="signUp.done.detailsTabletParentBullet1"
                  options={{ email }}
                  fontSize={4}
                  lineHeight={3}
                  color="slateGrey"
                  tag={Text}
                />
              </LiContainer>
              <LiContainer>
                <I18n
                  textAlign="left"
                  i18nKey="signUp.done.detailsTabletParentBullet3"
                  fontSize={4}
                  lineHeight={3}
                  fontWeight={700}
                  color="slateGrey"
                  tag={Text}
                />
              </LiContainer>
            </UlContainer>
          ) : (
            <UlContainer>
              <LiContainer>
                <I18n
                  textAlign="left"
                  i18nKey="signUp.done.detailsTabletBullet1"
                  fontSize={4}
                  options={{ email }}
                  lineHeight={3}
                  color="slateGrey"
                  tag={Text}
                />
              </LiContainer>
              <LiContainer>
                <I18n
                  textAlign="left"
                  i18nKey="signUp.done.detailsTabletBullet2"
                  fontSize={4}
                  lineHeight={3}
                  color="slateGrey"
                  tag={Text}
                />
              </LiContainer>
            </UlContainer>
          )
          /* eslint-enable */
        }
      </Flex>
      {widthApp > tabletWidth && (
        <Flex justifyContent="center" mt={2}>
          <ButtonNew
            id="btn-take-me-to-lyfta"
            mt={0}
            onClick={openInTeacherApp({ jwt }, redirect)}
            subtype="registration"
            disabledStyle={{ opacity: 0.7 }}
          >
            {type === 'parents' ? (
              <I18n i18nKey="signUp.done.ctaParent" />
            ) : (
              <I18n i18nKey="signUp.done.cta" />
            )}
          </ButtonNew>
        </Flex>
      )}
    </Container>
  )
}

export default SetupDone
