import * as React from 'react'

function SvgHeartLight(props) {
  return (
    <svg height="1em" viewBox="0 0 40 40" width="1em" {...props}>
      <path
        clipRule="evenodd"
        d="M30.1315 21.0045C28.4043 24.0664 25.3424 26.377 20.0018 30.5123C14.6612 26.3747 11.5994 24.0686 9.87212 21.0045C7.43444 16.7012 8.90748 13.0515 11.9875 11.6238C14.979 10.287 18.4947 11.5171 19.9973 14.4292C21.5021 11.5194 25.0179 10.2892 28.0093 11.6238C31.0939 13.0515 32.5601 16.7012 30.1315 21.0045M20 0C8.95428 0 0 8.95428 0 20C0 31.0457 8.95428 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95428 31.0457 0 20 0"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgHeartLight
