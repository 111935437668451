import { DateTime } from '@lyfta/components-i18n'
import { PropTypes } from '@lyfta/components-ui'
import React from 'react'

import { Container, ItemCheckMark, ItemContainer } from './styles'

export const OccurrenceItem = ({ item, selected, setSelected }) => {
  const openConfirm = () => {
    setSelected(item)
  }

  return (
    <Container onClick={openConfirm}>
      <ItemContainer selected={selected}>
        <ItemCheckMark active={selected} />
        {DateTime.fromISO(item.startDate).toLocaleString(DateTime.TIME_SIMPLE)}
      </ItemContainer>
    </Container>
  )
}

OccurrenceItem.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
}
