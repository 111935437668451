import { updateMeetingAttendee } from '@lyfta/components-data/src/Store/Actions/meetingAttendees'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { DateTime, I18n } from '@lyfta/components-i18n'
import { Flex, PropTypes, Text } from '@lyfta/components-ui'
import { openInTeacherApp } from '@lyfta/components-ui/src/Services/Utils'
import paths from 'Constants/paths'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { NextButton } from '../../../../../Components/NextButton'
import { ButtonContainer, FooterContainer, TextContainer } from '../../styles'

export const RegisterControls = ({ meetingAttendee }) => {
  const {
    id,
    meeting: { id: meetingId, zoomLink },
  } = meetingAttendee

  const dispatch = useDispatch()
  const history = useHistory()

  const jwt = useSelector(getJWT)

  const meetingDate = useMemo(
    () =>
      meetingAttendee && DateTime.fromISO(meetingAttendee.occurrence.startDate),
    [meetingAttendee],
  )

  const cutOffDate = meetingDate.plus({ minutes: 60 })

  const availableDate = meetingDate.minus({ minutes: 30 })

  const inFuture = useMemo(() => meetingDate && DateTime.now() < cutOffDate, [
    meetingDate,
  ])
  const startsSoon = useMemo(
    () => meetingDate && DateTime.now() > availableDate,
    [meetingDate],
  )

  const joinWebinar = e => {
    e.stopPropagation()
    e.preventDefault()
    if (zoomLink) window.open(zoomLink)
    dispatch(
      updateMeetingAttendee({ meetingId, id, attendanceStatus: 'attended' }),
    )
  }
  const rescheduleWebinar = e => {
    e.stopPropagation()
    e.preventDefault()
    history.push(paths.webinarBooking.reschedule.book(id, { meetingId }))
  }

  useEffect(() => {
    document.title = 'Registration - Webinar register'
  }, [])

  return (
    <FooterContainer>
      {!startsSoon && (
        <TextContainer>
          <I18n
            tag={Text}
            text="meetings.start.upcoming"
            textTransfrom="none"
          />
        </TextContainer>
      )}
      <ButtonContainer>
        {startsSoon && (
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            width={1}
          >
            <NextButton mb={[4, 0]} mr={[0, 4]} secondary onClick={joinWebinar}>
              <I18n
                mb={3}
                // as={Button}
                text="meetings.register.access"
              />
            </NextButton>
            <NextButton onClick={openInTeacherApp({ jwt })}>
              <I18n
                i18nKey="meetings.register.openLyfta"
                tag={React.Fragment}
              />
            </NextButton>
          </Flex>
        )}
        {inFuture && !startsSoon && (
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            width={1}
          >
            <NextButton
              mb={[4, 0]}
              mr={[0, 4]}
              secondary
              onClick={rescheduleWebinar}
            >
              <I18n
                i18nKey="meetings.register.reschedule"
                tag={React.Fragment}
              />
            </NextButton>
            <NextButton onClick={openInTeacherApp({ jwt })}>
              <I18n
                i18nKey="meetings.register.openLyfta"
                tag={React.Fragment}
              />
            </NextButton>
          </Flex>
        )}
      </ButtonContainer>
    </FooterContainer>
  )
}

RegisterControls.propTypes = {
  meetingAttendee: PropTypes.object.isRequired,
}
