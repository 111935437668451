import { ScrollableColumn } from '@lyfta/components-ui'
import { LyftaLogo } from '@lyfta/components-ui/src/Assets/Logo'
import PropTypes from 'prop-types'
import React from 'react'
import { renderRoutes } from 'react-router-config'

import { Container, Content, LogoContainer, MainHeader } from './styles'

const WebinarRegistrationLayout = ({ route }) => {
  return (
    <Container>
      <MainHeader>
        <LogoContainer>
          <LyftaLogo color="#fff" width={80} />
        </LogoContainer>
      </MainHeader>
      <ScrollableColumn>
        <Content>{renderRoutes(route.routes)}</Content>
      </ScrollableColumn>
    </Container>
  )
}

WebinarRegistrationLayout.propTypes = {
  route: PropTypes.object.isRequired,
}

export default WebinarRegistrationLayout
