import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { I18n } from '@lyfta/components-i18n'
import { Flex, Information, Text } from '@lyfta/components-ui'
import { openInTeacherApp } from '@lyfta/components-ui/src/Services/Utils'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPlan } from 'Store/Actions/signUp'

import { Heading1 } from '../../../../../Components/Headings'
import { NextButton } from '../../../../../Components/NextButton'
import { Container, Wrapper } from '../../../styles'

function ConfirmSkip() {
  const dispatch = useDispatch()
  const redirectToWebinar = () => dispatch(selectPlan())
  const jwt = useSelector(getJWT)

  return (
    <Container>
      <I18n i18nKey="signUp.confirmSkip.title" mb={0} tag={Heading1} />

      <Wrapper>
        <Flex flexDirection="column" width={1}>
          <Text textAlign="center">
            <Information />
          </Text>

          <Flex marginTop="2rem" width={1}>
            <I18n
              color="primary"
              fontSize="1rem"
              i18nKey="signUp.confirmSkip.content"
              tag={Text}
              textAlign="center"
            />
          </Flex>

          <Flex
            flexDirection={['column', 'row']}
            justifyContent="center"
            width={1}
          >
            <NextButton
              mb={[4, 0]}
              mr={[0, 4]}
              secondary
              onClick={openInTeacherApp({ jwt })}
            >
              <I18n
                i18nKey="signUp.confirmSkip.noWebinar"
                tag={React.Fragment}
              />
            </NextButton>
            <NextButton onClick={redirectToWebinar}>
              <I18n i18nKey="signUp.confirmSkip.webinar" tag={React.Fragment} />
            </NextButton>
          </Flex>
        </Flex>
      </Wrapper>
    </Container>
  )
}

export default ConfirmSkip
