import { styled } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'

export const FooterContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Container = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const ContainerDetails = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
`

export const TextContainer = styled(Flex)`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ButtonContainer = styled(Flex)`
  display: flex;
  width: 100%;
`
