import { loadMeetingOccurrences } from '@lyfta/components-data/src/Store/Actions/meetingOccurrences'
import { getMeetingOccurrences } from '@lyfta/components-data/src/Store/Selectors/meetingOccurrences'
import { DateTime, I18n } from '@lyfta/components-i18n'
import { BookingCalendar, Flex, PropTypes, Text } from '@lyfta/components-ui'
import { filter, map, sortBy } from 'lodash'
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Heading1, Heading2 } from '../../Headings'
import { OccurrenceItem } from './Occurrence'
import {
  Actions,
  BackButton,
  CalendarContainer,
  Container,
  DatesContainer,
  NextButton,
  NextIcon,
  PrevIcon,
  SelectionContainer,
} from './styles'

/* eslint-disable max-lines-per-function */
export const WebinarCalendar = ({ meetingId, navigateToConfirm }) => {
  const dispatch = useDispatch()

  const selectionContainerRef = useRef(null)

  const occurrences = useSelector(getMeetingOccurrences)

  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedOccurrence, setSelectedOccurrence] = useState(null)
  const [datesContainerHeight, setDatesContainerHeight] = useState(null)

  const occurrenceBeingSelected = !!selectedOccurrence
  const selectionContainerHeight = selectionContainerRef.current?.clientHeight

  useLayoutEffect(() => {
    function updateContainerHeight() {
      if (occurrenceBeingSelected) {
        setDatesContainerHeight(selectionContainerHeight)
      } else {
        setDatesContainerHeight(null)
      }
    }

    updateContainerHeight()

    window.addEventListener('resize', updateContainerHeight)

    return () => window.removeEventListener('resize', updateContainerHeight)
  }, [selectionContainerHeight, occurrenceBeingSelected])

  const selectMonth = month => {
    setSelectedDate(null)

    dispatch(
      loadMeetingOccurrences({
        meetingId,
        filter: {
          future: true,
          start_date: month.toFormat('yyyy-MM-dd'),
          end_date: month.plus({ month: 1 }).toFormat('yyyy-MM-dd'),
        },
      }),
    )
  }

  const selectDate = date => {
    setSelectedDate(date)
  }

  const resetDate = () => {
    setSelectedDate(null)
    setSelectedOccurrence(null)
  }

  const occurrencesOnDay = useMemo(() => {
    if (selectedDate) {
      return sortBy(
        filter(occurrences, o => {
          return DateTime.fromISO(o.startDate).hasSame(selectedDate, 'day')
        }),
        ['startDate'],
      )
    }

    return null
  }, [occurrences, selectedDate])

  useEffect(() => {
    document.title =
      'Registration - Choose a webinar to attend to unlock full access'
  }, [])

  return (
    <Container>
      <Flex alignSelf="center" display={['none', 'flex']} maxWidth={600} mb={7}>
        <I18n i18nKey="signUp.bookWebinar.title" tag={Heading1} />
      </Flex>
      <SelectionContainer ref={selectionContainerRef}>
        <CalendarContainer display={selectedDate ? ['none', 'flex'] : 'flex'}>
          <BookingCalendar
            eventsAvailable={occurrences}
            onMonthChange={selectMonth}
            onSelect={selectDate}
          />
        </CalendarContainer>
        {selectedDate && (
          <Flex display={['flex', 'none']} mb={4} order={-3}>
            <BackButton fontSize={14} small onClick={resetDate}>
              <PrevIcon />
              <I18n
                i18nKey="signUp.bookWebinar.backToCalendar"
                tag={React.Fragment}
              />
            </BackButton>
          </Flex>
        )}
        {selectedDate && (
          <Flex
            alignItems="center"
            alignSelf="center"
            display={['flex', 'none']}
            flexDirection="column"
            maxWidth={600}
            mb={4}
            order={-2}
          >
            <Flex mb={2}>
              <I18n i18nKey="signUp.bookWebinar.chooseTime" tag={Heading1} />
            </Flex>
            <Flex mb={1}>
              <Heading2>
                {DateTime.fromISO(selectedDate).toLocaleString('EEEE')}
              </Heading2>
            </Flex>
            <Text color="primary">
              {DateTime.fromISO(selectedDate).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY,
              )}
            </Text>
          </Flex>
        )}
        <DatesContainer
          flex={[selectedDate ? '1 1 100%' : 'none', '0 1 300px']}
          height={['auto', datesContainerHeight]}
        >
          {selectedDate && (
            <Flex alignItems="center" p={2}>
              <Text color="primary" fontSize={16} fontWeight={700}>
                {DateTime.fromISO(selectedDate).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY,
                )}
              </Text>
            </Flex>
          )}
          {map(occurrencesOnDay, o => (
            <OccurrenceItem
              item={o}
              key={o.startDate}
              selected={selectedOccurrence && selectedOccurrence.id === o.id}
              setSelected={setSelectedOccurrence}
            />
          ))}
          {!occurrencesOnDay && (
            <Flex mb={[5, 0]}>
              <I18n
                color="primary"
                fontSize={[20, 18]}
                i18nKey="signUp.bookWebinar.chooseDate"
                tag={Text}
                textAlign={['center', 'left']}
              />
            </Flex>
          )}
        </DatesContainer>
      </SelectionContainer>
      <Actions hidden={!selectedOccurrence}>
        <NextButton onClick={navigateToConfirm(selectedOccurrence)}>
          <I18n i18nKey="signUp.bookWebinar.cta" />
          <NextIcon />
        </NextButton>
      </Actions>
    </Container>
  )
}

WebinarCalendar.propTypes = {
  meetingId: PropTypes.number.isRequired,
  navigateToConfirm: PropTypes.func.isRequired,
}
