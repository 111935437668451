import { styled, themeGet } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'

export const ShowPasswordButton = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  appearance: none;
`

export const LinkPrivacy = styled.a`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: ${themeGet('colors.primary')};
`

export const CheckboxLabel = styled(Flex)`
  align-items: left;
  padding-left: 30px;
  margin-top: -20px;
`
