import { BREAKPOINTS, styled } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { Flex } from '@lyfta/components-ui'

export const FormContainer = styled(Flex).attrs(() => ({ mt: 2 }))`
  width: 100%;
  min-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > form {
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    min-width: 200px;
  }
`
