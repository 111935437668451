import { Flex, css, styled, themeGet } from '@lyfta/components-ui'

export const IconContainer = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  svg,
  path {
    fill: ${themeGet('colors.white')};

    ${({ accent }) =>
      accent &&
      css`
        fill: ${themeGet('colors.primary')};
      `};
  }
`

export const WebinarDetailsItem = styled(Flex).attrs(() => ({
  fontSize: ['2em', '2.5em'],
  alignItems: 'center',
}))``

WebinarDetailsItem.defaultProps = {
  mb: [3, 4],
}
