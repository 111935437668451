import { I18n } from '@lyfta/components-i18n'
import { AnimatedInput, ButtonNew, Flex } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import { InputsContainer, UnlockDiv } from '../styles'

const WelcomeFormFieldset = ({ form, handleSubmit, monochrome, id }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Flex width="100%" flexDirection="column">
        <UnlockDiv>
          <I18n text="signUp.welcome.subtitle" />
        </UnlockDiv>
        <InputsContainer>
          <AnimatedInput
            description=""
            id={id}
            label={<I18n text="fields.workemail" />}
            mb={2}
            inputProps={{
              color: 'black_900',
              height: '70px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              minHeight: '57px',
            }}
            name="email"
            type="email"
            onChange={form.mutators.handleEmailEntry}
          />

          <ButtonNew
            id={`btn-${id}`}
            monochromeInverse={monochrome}
            onClick={handleSubmit}
            minHeight="57px"
            style={{
              fontWeight: '600',
              fontFamily: 'Poppins',
              letterSpacing: 1,
              fontSize: '16px',
              boxShadow: 'none',
            }}
          >
            <I18n text="signUp.welcome.cta" />
          </ButtonNew>
        </InputsContainer>
      </Flex>
    </form>
  )
}

WelcomeFormFieldset.defaultProps = {
  id: '',
}

WelcomeFormFieldset.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  monochrome: PropTypes.bool.isRequired,
}

export default WelcomeFormFieldset
