import { requestCreateChild } from '@lyfta/components-data/src/Store/Actions/users'
import { I18n } from '@lyfta/components-i18n'
import { LyftaForm } from '@lyfta/components-ui'
import React, { useEffect } from 'react'

import { Container, Heading1 } from '../../styles'
import fieldset from './support/fields'

const ChildrenAccount = () => {
  const initialFormState = {
    children: [
      {
        role: 'home_student',
      },
    ],
  }

  useEffect(() => {
    document.title = 'Registration - Tell us about your children'
  }, [])

  const validate = values => {
    const errors = {}

    values.children.forEach(value => {
      if (!value || typeof value !== 'object' || !value.firstName) {
        errors.firstName = ['required']
      }
      if (!value || typeof value !== 'object' || !value.username) {
        errors.username = ['required']
      }
    })

    return errors
  }

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.childrenAccount.title" tag={Heading1} />

      <LyftaForm
        createAction={requestCreateChild}
        fields={fieldset}
        initialValues={initialFormState}
        modelName="teachers"
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        noWrap
        validate={validate}
        withoutHeader
        wrapperProps={{ flex: 20, flexDirection: 'column', width: '100%' }}
      />
    </Container>
  )
}

export default ChildrenAccount
