import { I18n } from '@lyfta/components-i18n'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Container, Heading1 } from '../../styles'
import ResourcesForm from './Form'
import { FormContainer } from './styles'

const ParentResources = () => {
  const history = useHistory()

  const submitParentResource = () => {
    history.push(paths.signUp.createAccount.childrenAccount)
  }
  useEffect(() => {
    document.title = 'Registration - Resources'
  }, [])

  return (
    <Container>
      <I18n i18nKey="signUp.parentResources.title" tag={Heading1} />

      <FormContainer>
        <ResourcesForm onSubmit={submitParentResource} />
      </FormContainer>
    </Container>
  )
}

export default ParentResources
