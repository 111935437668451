import { createMeetingAttendee } from '@lyfta/components-data/src/Store/Actions/meetingAttendees'
import { loadMeetingOccurrence } from '@lyfta/components-data/src/Store/Actions/meetingOccurrences'
import { getCurrentMeetingOccurrence } from '@lyfta/components-data/src/Store/Selectors/meetingOccurrences'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { DateTime, I18n } from '@lyfta/components-i18n'
import {
  CalendarAlt,
  Clock,
  Flex,
  Globe2,
  PropTypes,
  Text,
} from '@lyfta/components-ui'
import { openInTeacherApp } from '@lyfta/components-ui/src/Services/Utils'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'
import ICalendarLink from 'react-icalendar-link'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Heading1 } from '../../../../Components/Headings'
import { IconContainer } from '../../../../Components/IconContainer'
import { NextButton } from '../../../../Components/NextButton'
import { Container, HeartIcon, Wrapper } from '../../styles'
import { WebinarDetailsItem } from './styles'

const WebinarBookingConfirmation = ({ occurrence }) => {
  const { meetingId, id } = useParams()
  const history = useHistory()
  const event = occurrence?.id
    ? {
        title: occurrence?.name,
        description: occurrence?.name,
        startTime: occurrence?.startDate,
        endTime: occurrence?.endDate,
        location: 'Online',
      }
    : null

  const jwt = useSelector(getJWT)

  const goToInvite = () => {
    history.push(paths.webinarBooking.invite(id, { meetingId }))
  }

  useEffect(() => {
    document.title = 'Registration - Webinar booked'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="bookingConfirmation.title" tag={Heading1} />

      <Wrapper alignItems="center" flexDirection="column">
        <Flex alignItems="center" flexDirection="column">
          <Flex fontSize="15px" textAlign="center" width={1}>
            <I18n
              fontSize={5}
              i18nKey="bookingConfirmation.webinarDetailsText"
            />
          </Flex>

          <Flex
            alignItems="center"
            backgroundColor="primary"
            borderRadius="30px"
            flexDirection="column"
            justify-content="center"
            mb={5}
            mt={5}
            style={{ boxShadow: '0 0 50px 0 rgba(0,0,0,0.50)' }}
            width={1}
          >
            <Flex>
              <Flex
                color="white"
                fontSize={5}
                fontWeight={700}
                ml={5}
                mr={5}
                mt={5}
                style={{
                  letterSpacing: '3px',
                }}
                textAlign="center"
              >
                <I18n
                  i18nKey="bookingConfirmation.webinarDetails"
                  tag={React.Fragment}
                />
              </Flex>
            </Flex>

            <Flex flexDirection="column" m={5}>
              <WebinarDetailsItem mr={3}>
                <Flex mr={3}>
                  <IconContainer dark>
                    <Globe2 width={32} />
                  </IconContainer>
                </Flex>
                <I18n
                  color="white"
                  fontWeight={700}
                  i18nKey="bookingConfirmation.locationOnline"
                  tag={Text}
                />
              </WebinarDetailsItem>
              <WebinarDetailsItem as={ICalendarLink} event={event}>
                <Flex mr={3}>
                  <IconContainer dark>
                    <CalendarAlt width={32} />
                  </IconContainer>
                </Flex>
                <Text
                  color="white"
                  fontWeight={700}
                  style={{ textDecoration: 'underline' }}
                >
                  {DateTime.fromISO(occurrence?.startDate, {
                    setZone: true,
                  }).toLocaleString(DateTime.DATE_HUGE)}
                </Text>
              </WebinarDetailsItem>
              <WebinarDetailsItem>
                <Flex mr={3}>
                  <IconContainer dark>
                    <Clock width={32} />
                  </IconContainer>
                </Flex>
                <Text color="white" fontWeight={700}>
                  {DateTime.fromISO(occurrence?.startDate, {
                    setZone: false,
                  }).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
                </Text>
              </WebinarDetailsItem>
            </Flex>
          </Flex>

          {!occurrence?.id ? (
            <Flex mb={[8, 10]}>
              <I18n i18nKey="actions.loading" tag={Text} />
            </Flex>
          ) : (
            <>
              <Flex
                flexDirection={['column', 'row']}
                justifyContent="center"
                width={1}
              >
                <NextButton
                  mb={[4, 0]}
                  mr={[0, 4]}
                  secondary
                  onClick={openInTeacherApp({ jwt })}
                >
                  <I18n
                    i18nKey="bookingConfirmation.takeMeToLyfta"
                    tag={React.Fragment}
                  />
                </NextButton>
                <NextButton onClick={goToInvite}>
                  <I18n
                    i18nKey="bookingConfirmation.inviteColleagues"
                    tag={React.Fragment}
                  />
                  <HeartIcon dark />
                </NextButton>
              </Flex>
            </>
          )}
        </Flex>
      </Wrapper>
    </Container>
  )
}

WebinarBookingConfirmation.propTypes = {
  occurrence: PropTypes.object.isRequired,
}

/* eslint-disable max-lines-per-function */
const BookOnWebinar = () => {
  const { meetingId, id } = useParams()

  const dispatch = useDispatch()

  const occurrence = useSelector(getCurrentMeetingOccurrence)

  useEffect(() => {
    const relationships = {
      meeting: {
        data: {
          type: 'meetings',
          id: meetingId,
        },
      },
      occurrence: {
        data: {
          type: 'meetingOccurrences',
          id,
        },
      },
    }

    dispatch(
      createMeetingAttendee({
        meetingId,
        relationships,
        attendance_status: 'scheduled',
      }),
    )
    dispatch(loadMeetingOccurrence(id, { meetingId }))
  }, [dispatch, meetingId, id])
  return <WebinarBookingConfirmation occurrence={occurrence} />
}

export default BookOnWebinar
