import { createSelector } from 'reselect'

export const getState = state => state.signUp

export const getEmail = createSelector(getState, state => state.email)
export const getInviteToken = createSelector(
  getState,
  state => state.inviteToken,
)
export const getFirstName = createSelector(getState, state => state.firstName)
export const getLastName = createSelector(getState, state => state.lastName)
export const getSource = createSelector(getState, state => state.source)
export const getProcessing = createSelector(getState, state => state.processing)
export const getSignUpType = createSelector(getState, state => state.signUpType)
