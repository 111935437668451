import * as React from 'react'

function SvgGlobe(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000" fillRule="nonzero">
        <path d="M65.303 89.903H52.51c-.548 0-.913-.363-.913-.907s.365-.907.913-.907h16.905a39.06 39.06 0 004.66-2.721h-25.22c-.548 0-.914-.363-.914-.907s.366-.907.914-.907h27.779c1.096-.816 2.101-1.723 3.106-2.72H44.286c-.548 0-.913-.364-.913-.908s.365-.907.913-.907h37.19c.823-.907 1.554-1.814 2.285-2.72H51.597c-.549 0-.914-.363-.914-.908 0-.544.365-.907.914-.907h33.535c.64-.907 1.187-1.813 1.736-2.72H55.252c-.549 0-.914-.363-.914-.907 0-.545.365-.907.914-.907h32.62c.458-.907.915-1.814 1.28-2.721H39.718c-.549 0-.914-.363-.914-.907s.365-.907.914-.907h50.165c.366-.907.64-1.814.914-2.721H35.149c-.548 0-.914-.363-.914-.907s.366-.907.914-.907h56.196c.183-.907.457-1.814.548-2.72H37.89c-.548 0-.914-.364-.914-.908s.366-.907.914-.907h54.277l.275-2.72H54.338c-.548 0-.914-.363-.914-.908 0-.544.366-.907.914-.907h38.195v-.997-1.723H47.941c-.548 0-.913-.363-.913-.907 0-.545.365-.907.913-.907h44.41l-.275-2.721H52.51c-.548 0-.913-.363-.913-.907s.365-.907.913-.907h39.2c-.182-.907-.365-1.814-.639-2.721H63.475c-.548 0-.913-.363-.913-.907s.365-.907.913-.907h27.14c-.275-.907-.64-1.814-1.006-2.72H55.252c-.549 0-.914-.364-.914-.908s.365-.907.914-.907h33.535c-.457-.907-.823-1.814-1.371-2.72H52.51c-.548 0-.913-.363-.913-.908 0-.544.365-.907.913-.907h33.9c-.547-.907-1.187-1.814-1.827-2.72H55.252c-.549 0-.914-.363-.914-.907 0-.545.365-.907.914-.907h27.96a33.219 33.219 0 00-2.467-2.721H35.15c-.548 0-.914-.363-.914-.907s.366-.907.914-.907h43.769a43.47 43.47 0 00-3.29-2.721H37.89c-.548 0-.914-.363-.914-.907s.366-.907.914-.907h34.997c-1.645-.998-3.29-1.905-5.117-2.72H37.89c-.548 0-.914-.364-.914-.908 0-.272.092-.453.274-.635C20.53 16.802 8.467 32.401 8.467 50.813c0 23.037 18.823 41.72 42.033 41.72 5.208 0 10.234-.907 14.803-2.63zM37.423 10.335h25.22C58.67 9.18 54.42 8.467 49.987 8.467c-4.434 0-8.592.712-12.564 1.868z" />
      </g>
    </svg>
  )
}

export default SvgGlobe
