/* eslint-disable */
import {
  setEmail,
  setFirstname,
  setLastname,
  setUserType,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getEmail } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import { BREAKPOINTS } from '@lyfta/components-theme'
import { breakpointsNumber } from '@lyfta/components-theme/src/themes/Main'
import {
  AssistantTeacher,
  ButtonNew,
  Check2,
  ExecutiveLeader,
  LeadershipTeam,
  Separator,
  Teacher,
  Text,
} from '@lyfta/components-ui'
import { map } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container, Heading1, NextIcon } from '../../styles'
import { LinkPrivacy } from '../CreateAccount/styles'
import { SectionHeader } from '../OrganizationSetup/Form/styles'
import {
  IconContainer,
  Link,
  ParentOtherText,
  SeparatorContainer,
  TypesContainer,
  UserTypeContainer,
  UserTypeIcon,
  WhiteTitle,
} from './styles'

const ROLES = [
  {
    key: 'exec_leader',
    icon: <ExecutiveLeader key="exec_leader" />,
  },
  {
    key: 'leadership_team',
    icon: <LeadershipTeam key="leadership_team" />,
  },
  {
    key: 'teacher',
    icon: <Teacher key="teacher" />,
  },
  {
    key: 'teaching_assistant',
    icon: <AssistantTeacher key="teaching_assistant" />,
  },
]

const SetUserType = () => {
  const dispatch = useDispatch()
  const viewer = useSelector(getViewer)
  const userEmail = useSelector(getEmail)
  const { search } = useLocation()

  const [selectedType, setSelectedType] = useState(null)
  const [mouseOverType, setMouseOverType] = useState(false)
  const [noRole, setNoRole] = useState(false)
  const [confirmation, setConfirmation] = useState(false)

  const params = window?.location
    ? new URLSearchParams(search)
    : {
        get() {
          return ''
        },
      }
  const emailURL = params.get('email')
  const firstnameURL = params.get('first_name')
  const lastnameURL = params.get('last_name')

  if (emailURL) dispatch(setEmail(emailURL))
  if (firstnameURL) dispatch(setFirstname(firstnameURL))
  if (lastnameURL) dispatch(setLastname(lastnameURL))

  const selectType = userType => () => {
    setSelectedType(userType)
  }

  const { innerWidth: widthApp } = window
  const tabletWidth = breakpointsNumber[BREAKPOINTS.TABLET]

  const handleMouseLeave = () => {
    setMouseOverType(false)
  }
  const handleMouseEnter = userType => () => {
    setMouseOverType(userType)
  }
  const changeNoRole = () => {
    setNoRole(!noRole)
    setSelectedType(null)
  }
  const goToHubspotForm = () => {
    window.location.href = `https://share.hsforms.com/1jNUsNH1CSheteXHlHAsReQ4e5py?firstname=${viewer.firstName}&lastname=${viewer.lastName}&email=${viewer.email}`
  }

  const proceedToOrganization = useCallback(() => {
    if (selectedType) dispatch(setUserType(selectedType))
  }, [selectedType])

  const handleContinue = () => {
    if (selectedType) proceedToOrganization()
    else goToHubspotForm()
  }

  useEffect(() => {
    document.title = 'Registration - What is your role?'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.userType.title" tag={Heading1} />
      <I18n text="signUp.userType.prompt" tag={SectionHeader} mb={0} />
      {widthApp > tabletWidth && (
        <I18n
          textAlign="center"
          i18nKey="signUp.userType.details"
          lineHeight={3}
          fontSize={4}
          color="slateGrey"
          tag={Text}
          m={2}
        />
      )}
      <TypesContainer>
        {map(ROLES, ({ key: role, icon }) => (
          <UserTypeContainer
            key={role}
            active={selectedType === role}
            onClick={selectType(role)}
            onMouseLeave={() => handleMouseLeave()}
            onMouseEnter={handleMouseEnter(role)}
            id={`div-select-role-${role.replaceAll('_', '-')}`}
          >
            {mouseOverType === role && widthApp > tabletWidth ? (
              <I18n text={`users.jobRoles.${role}`} tag={WhiteTitle} />
            ) : (
              <I18n text={`users.jobRoles.${role}`} />
            )}
            <UserTypeIcon>
              {selectedType === role ? (
                <IconContainer>
                  <Check2 />
                </IconContainer>
              ) : mouseOverType === role && widthApp > tabletWidth ? (
                <>
                  <I18n
                    color="white"
                    text={`users.rolesText.${role}`}
                    tag={Text}
                  />
                </>
              ) : (
                icon
              )}
            </UserTypeIcon>
          </UserTypeContainer>
        ))}
      </TypesContainer>
      <SeparatorContainer>
        <Separator label={getTranslation('auth.separatorLabel')} />{' '}
      </SeparatorContainer>
      <Link onClick={changeNoRole}>
        <I18n text="signUp.userType.other" />
      </Link>

      {noRole && (
        <ParentOtherText alignItems="center">
          <TransLink
            i18nKey="signUp.confirmRole"
            components={{
              Link: (
                <LinkPrivacy
                  href={getTranslation('signUp.links.hubspotForm', {
                    email: userEmail,
                  })}
                  to={getTranslation('signUp.links.hubspotForm')}
                  target="_blank"
                />
              ),
            }}
          />
        </ParentOtherText>
      )}
      <ButtonNew
        onClick={handleContinue}
        disabled={selectedType == null && !confirmation}
        mt={4}
        width={['100%', 330]}
        id="btn-proceed-sign-up"
        subtype="registration"
        disabledStyle={{ opacity: 0.7 }}
      >
        <I18n i18nKey="signUp.userType.cta" />
        <NextIcon />
      </ButtonNew>
    </Container>
  )
}

export default SetUserType
