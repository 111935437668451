import { css, styled, themeGet } from '@lyfta/components-theme'
import { Check2, Flex } from '@lyfta/components-ui'
import React from 'react'

export const Container = styled(Flex).attrs(() => ({
  mb: 2,
  px: 2,
}))`
  cursor: pointer;
  flex-direction: row;
`

export const ItemCheckMark = styled(Flex).attrs(({ active }) => ({
  children: active && <Check2 />,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${themeGet('colors.primary')};
  border-radius: 100%;
  transition: all 0.17s;

  svg,
  path {
    fill: ${themeGet('colors.primary')};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.white')};
    `};
`

export const ItemContainer = styled(Flex).attrs(({ selected }) => ({
  backgroundColor: selected ? 'primary' : 'transparent',
  color: selected ? 'white' : 'primary',
  py: 3,
  flexDirection: 'row',
}))`
  position: relative;
  flex: 1 1 100%;
  cursor: pointer;
  border-radius: 100vw;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.17s;

  border: 2px solid ${themeGet('colors.primary')};

  &:hover {
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.white')};

    ${ItemCheckMark} {
      background-color: ${themeGet('colors.white')};
    }
  }

  ${({ selected }) => selected && themeGet('buttonShadow.default')};
`
