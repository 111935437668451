import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'

import formFields from './form'

const ResourcesForm = ({ onSubmit }) => {
  return (
    <Form
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      // mutators={{ handleEmailEntry }}
      render={formFields}
      onSubmit={onSubmit}
    />
  )
}
ResourcesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
export default ResourcesForm
