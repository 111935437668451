import { css, styled, themeGet } from '@lyfta/components-theme'
import { ButtonNew, Flex, Next } from '@lyfta/components-ui'
import React from 'react'

export const Container = styled(Flex)`
  flex-direction: column;
  flex: 1;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
export const SelectionContainer = styled(Flex).attrs(() => ({
  mx: [0, 3],
  justifyContent: 'center',
  flexDirection: ['column', 'row'],
}))`
  flex: 1;
  overflow: hidden;
`

export const DatesContainer = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  order: [-1, 1],
  pb: 4,
}))`
  overflow-x: auto;
`

export const CalendarContainer = styled(Flex).attrs(() => ({
  flex: ['1 1 100%', 'none'],
  flexDirection: 'column',
  mr: [0, 4],
}))``

export const BackButton = styled(ButtonNew)`
  font-size: 14px;
  text-transform: none;
  border-radius: 100vw;
  background-color: ${themeGet('colors.primary')};
  padding-left: 10px;
`

export const PrevIcon = styled(Flex).attrs(() => ({
  children: <Next />,
  mr: 2,
  width: 20,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  background-color: ${themeGet('colors.white')};
  opacity: 0.3;

  svg {
    transform: rotate(180deg);
  }

  svg,
  path {
    fill: ${themeGet('colors.primary')};
  }
`

export const NextButton = styled(ButtonNew).attrs(() => ({
  width: ['100%', 300],
  borderRadius: '100vw',
  height: 50,
  fontWeight: 700,
  shadow: true,
  type: 'button',
}))`
  background-color: ${themeGet('colors.primary')};

  ${({ secondary }) =>
    secondary &&
    css`
      opacity: 0.7;
    `};
`

NextButton.defaultProps = {
  fontSize: 20,
}

export const NextIcon = styled(Flex).attrs(() => ({
  children: <Next />,
  minWidth: 32,
  minHeight: 32,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  opacity: 0.3;
  background-color: ${themeGet('colors.white')};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 1.5em;

  svg,
  path {
    fill: ${themeGet('colors.primary')};
  }
`

export const Actions = styled(Flex).attrs(() => ({
  alignSelf: 'center',
  width: ['100%', 'auto'],
}))`
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `};
`
