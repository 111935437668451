import paths from 'Constants/paths'
import { ChangePassword, Forgot, SignIn } from 'Containers/Pages/Auth'

export default [
  {
    path: paths.signIn,
    exact: true,
    component: SignIn,
  },
  {
    path: paths.forgot,
    exact: true,
    component: Forgot,
  },
  {
    path: paths.changePassword,
    exact: true,
    component: ChangePassword,
  },
]
