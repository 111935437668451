import { initialise } from '@lyfta/components-i18n'

import mainEn from './translations/en.json'
import mainFi from './translations/fi.json'

export const additionalTranslations = [
  { language: 'en', namespace: 'registration', translations: mainEn },
  { language: 'fi', namespace: 'registration', translations: mainFi },
]

initialise(additionalTranslations)
