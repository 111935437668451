import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import {
  BREAKPOINTS,
  ButtonNew,
  Flex,
  css,
  styled,
  themeGet,
} from '@lyfta/components-ui'

export const UserCount = styled(Flex).attrs(() => ({
  my: [10, 8],
}))`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;

  @media screen and (min-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    column-gap: 16px;
    row-gap: 16px;
  }
`

export const UserCountItem = styled(ButtonNew).attrs(() => ({
  type: 'button',
  width: [50, 60],
  height: [50, 60],
  fontSize: [16, 20],
}))`
  background-color: transparent;
  border: 2px solid ${themeGet('colors.primary')};
  color: ${themeGet('colors.primary')};
  border-radius: 100%;
  border-width: 2px;
  justify-content: center;
  font-weight: 700;
  transition: all 0.17s;

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.primary')};
      color: ${themeGet('colors.white')};
    `};
`
