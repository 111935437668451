import { DateTime, getCurrentLanguage } from '@lyfta/components-i18n'
import { get } from 'lodash'
import { createSelector } from 'reselect'

import { getAllEntities, getEntity, getEntityStateField } from '../../utils'

export const getMeetingOccurrences = getAllEntities('meetingOccurrences')
export const getCurrentMeetingOccurrence = getEntity('meetingOccurrences')

export const getMeetingOccurrenceName = createSelector(
  getCurrentMeetingOccurrence,
  meetingOccurrence => {
    return DateTime.fromISO(get(meetingOccurrence, 'startDate'), {
      locale: getCurrentLanguage(),
    }).toLocaleString(DateTime.DATETIME_MED)
  },
)

export const getIsLoaded = getEntityStateField('meetingOccurrences', 'isLoaded')
export const getIsLoading = getEntityStateField(
  'meetingOccurrences',
  'isLoading',
)
export const getPaged = getEntityStateField('meetingOccurrences', 'paged')
