import {
  INITIAL_STEPS_ORGANIZATION,
  INITIAL_STEPS_PARENT,
} from 'Constants/signUp'

export function getCompletedStepsRelativeTo(step, registrationPath) {
  const steps =
    registrationPath === 'parent'
      ? [...INITIAL_STEPS_PARENT].map((value, index) =>
          index < step
            ? { ...value, completed: true, active: false }
            : { ...value, completed: false, active: false },
        )
      : [...INITIAL_STEPS_ORGANIZATION].map((value, index) =>
          index < step
            ? { ...value, completed: true, active: false }
            : { ...value, completed: false, active: false },
        )
  steps[step].active = true

  return steps
}
