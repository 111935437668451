/* eslint-disable */
import { googleAPIKey } from '@lyfta/components-config'
import { translateGeocoderResultToStructured } from '@lyfta/components-data'
import { lookupOrganization } from '@lyfta/components-data/src/Store/Actions/organizationLookups'
import { getOrganizationLookups } from '@lyfta/components-data/src/Store/Selectors/organizationLookups'
import {
  I18n,
  generateCountryList,
  getCurrentLanguage,
} from '@lyfta/components-i18n'
import {
  AnimatedPlacesInput,
  AnimatedTypeAhead,
  ButtonNew,
  Flex,
} from '@lyfta/components-ui'
import { forOwn, isEmpty, pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { NextButton, NextIcon } from '../../../styles'
import {
  CountryButtonContainer,
  CountrySection,
  InputDescription,
  LocationCheckedIcon,
  SectionHeader,
} from './styles'

/* eslint-disable max-lines-per-function */
const WelcomeFormFieldset = ({ form, handleSubmit }) => {
  const locale = getCurrentLanguage()
  const dispatch = useDispatch()
  const lookupItems = useSelector(getOrganizationLookups)
  const countryValues = useMemo(() => generateCountryList(), [])
  const { values } = form.getState()

  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const resetForm = () => {
    const clearObject = {
      address1: null,
      city: null,
      enteredSchoolName: null,
      place: null,
      postalCode: null,
      schoolId: null,
      state: null,
      name: null,
    }

    forOwn(clearObject, (v, k) => form.mutators.setValue(k, v))
    setSearchPerformed(false)
    setSubmitting(false)
  }

  useEffect(() => {
    const countryCode = form.getFieldState('country')?.value

    if (countryCode !== undefined && !isEmpty(countryCode)) {
      const showCountry = !['gb'].includes(countryCode)
      setShowCountrySelector(showCountry)
    }
    resetForm()
  }, [form.getFieldState('country')?.value])

  const setCountry = countryCode => e => {
    e.preventDefault()
    e.stopPropagation()
    form.mutators.setValue('country', countryCode)
    resetForm()
  }

  const openCountrySelector = e => {
    e.preventDefault()
    e.stopPropagation()
    form.mutators.setValue('country', '')
    setShowCountrySelector(true)
  }

  const selectGoogleResult = useCallback(results => {
    const addressData = translateGeocoderResultToStructured(results)
    forOwn(addressData, (v, k) => form.mutators.setValue(k, v))
    setSearchPerformed(true)
  }, [])

  const onInputChange = ({ inputValue: name }) => {
    form.mutators.setValue('enteredSchoolName', name)

    dispatch(
      lookupOrganization({
        address: {
          ...pick(values, ['country', 'city', 'postalCode']),
          name,
        },
      }),
    )
  }

  const onSelectSchool = item => {
    setTimeout(() => forOwn(item, (v, k) => form.mutators.setValue(k, v)), 100)
  }

  const onSubmit = e => {
    handleSubmit(e)
    setSubmitting(form.getState().valid)
  }

  const country = form.getState()?.values?.country
  return (
    <form
      onSubmit={handleSubmit}
      style={{ alignItem: 'center', width: '100%' }}
    >
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
        />
      </Helmet>

      <CountrySection>
        <I18n
          text="signUp.organizationSetup.form.country.prompt"
          tag={SectionHeader}
        />
        <CountryButtonContainer>
          <ButtonNew
            key="loc-gb-container"
            mr={[0, 4]}
            mt={3}
            width={['100%', 260]}
            onClick={setCountry('gb')}
            subtype="registration"
            secondary={country !== 'gb'}
            id="btn-select-country-gb"
          >
            <>
              <LocationCheckedIcon
                color={country !== 'gb' ? 'primary' : 'white'}
                key="loc-gb-icon"
                active={country === 'gb'}
              />
              <span key="loc-gb-text">
                {countryValues.find(c => c.id === 'gb')?.name}
              </span>
            </>
          </ButtonNew>
          <ButtonNew
            mr={[0, 4]}
            mt={3}
            onClick={openCountrySelector}
            subtype="registration"
            secondary={!showCountrySelector}
            id="btn-select-country-other"
          >
            <>
              <LocationCheckedIcon
                key="loc-other-icon"
                active={showCountrySelector}
              />
              <I18n
                key="loc-other-text"
                text="signUp.organizationSetup.form.other"
              />
            </>
          </ButtonNew>
        </CountryButtonContainer>
      </CountrySection>
      {showCountrySelector && (
        <AnimatedTypeAhead
          autoFocus
          id="specify-other-country"
          name="country"
          mb={3}
          mt={5}
          label={<I18n text="fields.country" />}
          items={countryValues}
        />
      )}
      {values.country && (
        <>
          <I18n
            text="signUp.organizationSetup.form.address.prompt"
            tag={SectionHeader}
          />
          <AnimatedPlacesInput
            autoFocus
            label={<I18n text="signUp.organizationSetup.form.address.label" />}
            name="place"
            mb={3}
            id="school-location"
            requestOptions={{
              types: ['geocode'],
              componentRestrictions: {
                country: [values.country.toLowerCase()],
              },
            }}
            selectCallback={selectGoogleResult}
          />
        </>
      )}

      {(values.city || values.postalCode) && (
        <>
          <I18n
            text="signUp.organizationSetup.form.schoolName.prompt"
            tag={SectionHeader}
          />

          <AnimatedTypeAhead
            keepValueOnNoItem
            autoFocus
            name="schoolId"
            mb={3}
            label={
              <I18n text="signUp.organizationSetup.form.schoolName.label" />
            }
            id="school-name"
            items={lookupItems}
            onInputValueChange={onInputChange}
            onSelectItem={onSelectSchool}
          />
          <I18n
            mb={3}
            tag={InputDescription}
            text="signUp.organizationSetup.form.schoolName.description"
          />
        </>
      )}

      {values.country &&
        (values.schoolId || values.enteredSchoolName) &&
        searchPerformed && (
          <Flex width={1} justifyContent="center">
            <ButtonNew
              mt={[3, 1]}
              subtype="registration"
              onClick={onSubmit}
              disabled={submitting}
              id="btn-proceed-sign-up"
            >
              <I18n i18nKey="signUp.organizationSetup.cta" />
              <NextIcon />
            </ButtonNew>
          </Flex>
        )}
    </form>
  )
}

WelcomeFormFieldset.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default WelcomeFormFieldset
