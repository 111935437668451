import * as React from 'react'

function SvgExecutiveLeader(props) {
  return (
    <svg
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_101_544)">
        <path
          d="M83.6664 48.3333V76.3331C83.6664 79.2787 81.2787 81.6664 78.3331 81.6664H11.6669C8.72131 81.6664 6.33362 79.2787 6.33362 76.3331V48.3333C6.33362 51.2788 8.72131 53.6665 11.6669 53.6665H78.3331C78.7836 53.6708 79.2322 53.6122 79.6667 53.4932C82.0221 52.8889 83.6688 50.7649 83.6664 48.3333V48.3333Z"
          fill="#1566C0"
        />
        <path
          d="M34.3335 15.0001C34.3335 12.0546 36.7212 9.66687 39.6667 9.66687H50.3333C53.2788 9.66687 55.6665 12.0546 55.6665 15.0001H60.9998V13.6665C60.9998 8.51208 56.8213 4.33362 51.6669 4.33362H38.3331C33.1787 4.33362 29.0002 8.51208 29.0002 13.6665V15.0001H34.3335Z"
          fill="#607D8B"
        />
        <path
          d="M11.6669 15.0001H78.3331C81.2787 15.0001 83.6664 17.3878 83.6664 20.3334V48.3333C83.6664 51.2788 81.2787 53.6665 78.3331 53.6665H11.6669C8.72131 53.6665 6.33362 51.2788 6.33362 48.3333V20.3334C6.33362 17.3878 8.72131 15.0001 11.6669 15.0001Z"
          fill="#00838F"
        />
        <path
          d="M79.6667 53.4932V76.3331C79.6667 79.2787 77.2791 81.6664 74.3335 81.6664H11.6669C8.72131 81.6664 6.33362 79.2787 6.33362 76.3331V53.4932C6.76758 53.6122 7.21619 53.6708 7.66663 53.6665H78.3331C78.7836 53.6708 79.2322 53.6122 79.6667 53.4932Z"
          fill="#2296F3"
        />
        <path
          d="M11.6669 15.0001H74.3335C77.2791 15.0001 79.6667 17.3878 79.6667 20.3334V48.3333C79.6667 51.2788 77.2791 53.6665 74.3335 53.6665H11.6669C8.72131 53.6665 6.33362 51.2788 6.33362 48.3333V20.3334C6.33362 17.3878 8.72131 15.0001 11.6669 15.0001Z"
          fill="#00BCD4"
        />
        <path
          d="M51.3574 60.3163L46.0242 66.5333C45.7666 66.83 45.3931 67.0002 45 67.0002C44.6069 67.0002 44.2334 66.83 43.9758 66.5333L38.6426 60.3163C38.4436 60.0856 38.3337 59.7914 38.3331 59.4868V47.0002H51.6669V59.4868C51.6663 59.7914 51.5564 60.0856 51.3574 60.3163Z"
          fill="#FEC108"
        />
        <path
          clipRule="evenodd"
          d="M62.3334 13.6665H78.3331C82.0135 13.6708 84.9957 16.6536 85 20.3334V76.3331C84.9957 80.0135 82.0135 82.9957 78.3331 83H11.6669C7.98645 82.9957 5.00427 80.0135 5 76.3331V20.3334C5.00427 16.6536 7.98645 13.6708 11.6669 13.6665H27.6666C27.6733 7.77844 32.4451 3.00671 38.3331 3H51.6669C57.5549 3.00671 62.3267 7.77844 62.3334 13.6665ZM51.6669 5.66663H38.3331C33.9166 5.6709 30.3375 9.25 30.3333 13.6665H33.1335C33.7714 10.5647 36.4996 8.33752 39.6667 8.33325H50.3333C53.5004 8.33752 56.2286 10.5647 56.8665 13.6665H59.6667C59.6625 9.25 56.0834 5.6709 51.6669 5.66663ZM54.1052 13.6665H35.8948C36.4618 12.0698 37.9718 11.0023 39.6667 10.9999H50.3333C52.0282 11.0023 53.5382 12.0698 54.1052 13.6665ZM78.3331 16.3331H11.6669C9.4574 16.3331 7.66663 18.1239 7.66663 20.3334V48.3333C7.66663 50.5427 9.4574 52.3335 11.6669 52.3335H37.0001V48.3333H35.6665C34.9304 48.3333 34.3335 47.7363 34.3335 47.0002C34.3335 46.2635 34.9304 45.6666 35.6665 45.6666H54.3335C55.0696 45.6666 55.6665 46.2635 55.6665 47.0002C55.6665 47.7363 55.0696 48.3333 54.3335 48.3333H52.9999V52.3335H78.3331C80.5426 52.3335 82.3334 50.5427 82.3334 48.3333V20.3334C82.3334 18.1239 80.5426 16.3331 78.3331 16.3331ZM39.6667 48.3333H50.3333V59.4612L44.9866 65.6666L39.6667 59.4868V48.3333ZM7.66663 76.3331C7.66663 78.5426 9.4574 80.3334 11.6669 80.3334H78.3331C79.3939 80.3334 80.4114 79.9116 81.1615 79.1615C81.9116 78.4114 82.3334 77.3939 82.3334 76.3331V53.6335C81.5485 54.2396 80.6366 54.6608 79.6667 54.8665V55.0001C79.6667 55.7362 79.0698 56.3331 78.3331 56.3331C77.597 56.3331 77.0001 55.7362 77.0001 55.0001H52.9999V59.4868C52.9993 60.1094 52.7759 60.7118 52.3694 61.1842L47.0361 67.4025C46.5247 67.9945 45.7819 68.3345 45 68.3345C44.2181 68.3345 43.4753 67.9945 42.9639 67.4025L37.6306 61.1824C37.2247 60.7106 37.0007 60.1094 37.0001 59.4868V55.0001H12.9999C12.9999 55.7362 12.403 56.3331 11.6669 56.3331C10.9302 56.3331 10.3333 55.7362 10.3333 55.0001V54.8665C9.36279 54.6608 8.45154 54.2384 7.66663 53.6317V76.3331Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          d="M14.3335 23H38.3331C39.0698 23 39.6667 22.4031 39.6667 21.6664C39.6667 20.9303 39.0698 20.3334 38.3331 20.3334H14.3335C13.5968 20.3334 12.9999 20.9303 12.9999 21.6664C12.9999 22.4031 13.5968 23 14.3335 23Z"
          fill="black"
        />
        <path
          d="M45 23H47.6666C48.4033 23 49.0002 22.4031 49.0002 21.6664C49.0002 20.9303 48.4033 20.3334 47.6666 20.3334H45C44.2639 20.3334 43.6664 20.9303 43.6664 21.6664C43.6664 22.4031 44.2639 23 45 23Z"
          fill="black"
        />
        <path
          d="M54.3335 23H71.6669C72.403 23 72.9999 22.4031 72.9999 21.6664C72.9999 20.9303 72.403 20.3334 71.6669 20.3334H54.3335C53.5968 20.3334 52.9999 20.9303 52.9999 21.6664C52.9999 22.4031 53.5968 23 54.3335 23Z"
          fill="black"
        />
        <path
          d="M11.6669 63.6305C12.403 63.6305 12.9999 63.0336 12.9999 62.2975V59.8652C12.9999 59.1292 12.403 58.5322 11.6669 58.5322C10.9302 58.5322 10.3333 59.1292 10.3333 59.8652V62.2975C10.3333 63.0336 10.9302 63.6305 11.6669 63.6305Z"
          fill="black"
        />
        <path
          d="M11.6669 70.9291C12.403 70.9291 12.9999 70.3322 12.9999 69.5961V67.1626C12.9999 66.4265 12.403 65.8296 11.6669 65.8296C10.9302 65.8296 10.3333 66.4265 10.3333 67.1626V69.5961C10.3333 70.3322 10.9302 70.9291 11.6669 70.9291Z"
          fill="black"
        />
        <path
          d="M49.8615 75.0001H47.428C46.6919 75.0001 46.095 75.597 46.095 76.3331C46.095 77.0698 46.6919 77.6667 47.428 77.6667H49.8615C50.5975 77.6667 51.1945 77.0698 51.1945 76.3331C51.1945 75.597 50.5975 75.0001 49.8615 75.0001Z"
          fill="black"
        />
        <path
          d="M78.3331 58.5438C77.597 58.5438 77.0001 59.1407 77.0001 59.8774V62.3091C77.0001 63.0458 77.597 63.6427 78.3331 63.6427C79.0698 63.6427 79.6667 63.0458 79.6667 62.3091V59.8774C79.6667 59.1407 79.0698 58.5438 78.3331 58.5438V58.5438Z"
          fill="black"
        />
        <path
          d="M27.9681 75.0001H25.5334C24.7968 75.0001 24.1998 75.597 24.1998 76.3331C24.1998 77.0698 24.7968 77.6667 25.5334 77.6667H27.9669C28.703 77.6667 29.2999 77.0698 29.2999 76.3331C29.2999 75.597 28.703 75.0001 27.9669 75.0001H27.9681Z"
          fill="black"
        />
        <path
          d="M71.7535 75.0001H69.3213C68.5852 75.0001 67.9883 75.597 67.9883 76.3331C67.9883 77.0698 68.5852 77.6667 69.3213 77.6667H71.7535C72.4896 77.6667 73.0865 77.0698 73.0865 76.3331C73.0865 75.597 72.4896 75.0001 71.7535 75.0001Z"
          fill="black"
        />
        <path
          d="M57.1588 75.0001H54.7253C53.9893 75.0001 53.3917 75.597 53.3917 76.3331C53.3917 77.0698 53.9893 77.6667 54.7253 77.6667H57.1588C57.8949 77.6667 58.4918 77.0698 58.4918 76.3331C58.4918 75.597 57.8949 75.0001 57.1588 75.0001Z"
          fill="black"
        />
        <path
          d="M20.6708 75.0001H18.2385C17.5024 75.0001 16.9055 75.597 16.9055 76.3331C16.9055 77.0698 17.5024 77.6667 18.2385 77.6667H20.6708C21.4069 77.6667 22.0038 77.0698 22.0038 76.3331C22.0038 75.597 21.4069 75.0001 20.6708 75.0001Z"
          fill="black"
        />
        <path
          d="M78.6108 73.188C78.2733 73.0824 77.9077 73.1154 77.594 73.2795C77.2809 73.4431 77.0453 73.7251 76.9397 74.0626C76.8152 74.4557 76.5173 74.7706 76.1322 74.9171C75.6756 75.0764 75.3418 75.4701 75.2582 75.9462C75.1752 76.4222 75.3558 76.9063 75.7306 77.2108C76.1059 77.5154 76.6162 77.5935 77.0654 77.4147C78.2214 76.9789 79.1131 76.0365 79.4855 74.8585C79.591 74.521 79.5581 74.1554 79.3939 73.8423C79.2297 73.5286 78.9483 73.2936 78.6108 73.188Z"
          fill="black"
        />
        <path
          d="M13.8629 74.9159C13.4796 74.767 13.1848 74.4514 13.0627 74.059C12.9333 73.5902 12.5598 73.2289 12.0868 73.116C11.6137 73.003 11.1169 73.1562 10.7904 73.5163C10.4632 73.8765 10.3582 74.3855 10.5157 74.8451C10.8838 76.0255 11.773 76.9709 12.9278 77.4104C13.6114 77.6496 14.3603 77.2993 14.6154 76.6218C14.8706 75.9449 14.5392 75.1875 13.8678 74.9159H13.8629Z"
          fill="black"
        />
        <path
          d="M42.5629 75.0001H40.1306C39.3945 75.0001 38.7976 75.597 38.7976 76.3331C38.7976 77.0698 39.3945 77.6667 40.1306 77.6667H42.5629C43.299 77.6667 43.8959 77.0698 43.8959 76.3331C43.8959 75.597 43.299 75.0001 42.5629 75.0001Z"
          fill="black"
        />
        <path
          d="M64.4562 75.0001H62.0239C61.2878 75.0001 60.6909 75.597 60.6909 76.3331C60.6909 77.0698 61.2878 77.6667 62.0239 77.6667H64.4562C65.1923 77.6667 65.7892 77.0698 65.7892 76.3331C65.7892 75.597 65.1923 75.0001 64.4562 75.0001Z"
          fill="black"
        />
        <path
          d="M78.3331 65.8412C77.597 65.8412 77.0001 66.4381 77.0001 67.1748V69.6064C77.0001 70.3431 77.597 70.9401 78.3331 70.9401C79.0698 70.9401 79.6667 70.3431 79.6667 69.6064V67.1748C79.6667 66.4381 79.0698 65.8412 78.3331 65.8412V65.8412Z"
          fill="black"
        />
        <path
          d="M35.2667 75.0001H32.8333C32.0972 75.0001 31.5002 75.597 31.5002 76.3331C31.5002 77.0698 32.0972 77.6667 32.8333 77.6667H35.2667C36.0028 77.6667 36.5997 77.0698 36.5997 76.3331C36.5997 75.597 36.0028 75.0001 35.2667 75.0001Z"
          fill="black"
        />
        <path
          d="M46.3336 58.9998C46.3336 59.7365 45.7361 60.3334 45 60.3334C44.2639 60.3334 43.6664 59.7365 43.6664 58.9998C43.6664 58.2637 44.2639 57.6667 45 57.6667C45.7361 57.6667 46.3336 58.2637 46.3336 58.9998Z"
          fill="black"
        />
        <path
          d="M46.3336 53.6665C46.3336 54.4032 45.7361 55.0001 45 55.0001C44.2639 55.0001 43.6664 54.4032 43.6664 53.6665C43.6664 52.9304 44.2639 52.3335 45 52.3335C45.7361 52.3335 46.3336 52.9304 46.3336 53.6665Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="90"
          id="filter0_d_101_544"
          width="90"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_101_544"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_101_544"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgExecutiveLeader
