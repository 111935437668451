import {
  setEmail,
  setFirstname,
  setLastname,
  setPath,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { I18n } from '@lyfta/components-i18n'
import { BREAKPOINTS } from '@lyfta/components-theme'
import { breakpointsNumber } from '@lyfta/components-theme/src/themes/Main'
import { Check2, ExecutiveLeader, Parent, Text } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import { map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Container, Heading1, NextIcon } from '../../styles'
import { SectionHeader } from '../OrganizationSetup/Form/styles'
import {
  IconContainer,
  NextButtonResponsive,
  TypesContainer,
  UserTypeContainer,
  UserTypeIcon,
  WhiteTitle,
} from './styles'

const ROLES = [
  {
    key: 'organization',
    icon: <ExecutiveLeader key="exec_leader" />,
  },
  {
    key: 'parent',
    icon: <Parent key="parent" />,
  },
]

const SetOrganizationType = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { search } = useLocation()
  const [selectedType, setSelectedType] = useState(null)
  const [mouseOverType, setMouseOverType] = useState(false)
  // eslint-disable-next-line
  const [confirmation, setConfirmation] = useState(false)

  const params = window?.location
    ? new URLSearchParams(search)
    : {
        get() {
          return ''
        },
      }
  const emailURL = params.get('email')
  const firstnameURL = params.get('first_name')
  const lastnameURL = params.get('last_name')

  if (emailURL) dispatch(setEmail(emailURL))
  if (firstnameURL) dispatch(setFirstname(firstnameURL))
  if (lastnameURL) dispatch(setLastname(lastnameURL))

  const selectType = userType => () => {
    setSelectedType(userType)
  }

  const { innerWidth: widthApp } = window
  const tabletWidth = breakpointsNumber[BREAKPOINTS.TABLET]

  const handleMouseLeave = () => {
    setMouseOverType(false)
  }
  const handleMouseEnter = userType => () => {
    setMouseOverType(userType)
  }

  const handleContinue = () => {
    if (selectedType === 'organization') {
      dispatch(setPath('organization'))
      history.push(paths.signUp.createAccount.userType)
      return true
    }
    // dispatch(setPath('parent'))
    // history.push(paths.signUp.createAccount.createParentAccount)
    window.open('https://holidayclub.lyfta.com')
    return true
  }

  useEffect(() => {
    document.title = 'Registration - Are you part of an organization?'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.organizationType.title" tag={Heading1} />
      <I18n mb={0} tag={SectionHeader} text="signUp.organizationType.prompt" />
      {widthApp > tabletWidth && (
        <I18n
          color="slateGrey"
          fontSize={4}
          i18nKey="signUp.organizationType.details"
          lineHeight={3}
          m={2}
          tag={Text}
          textAlign="center"
        />
      )}
      <TypesContainer>
        {map(ROLES, ({ key: role, icon }) => (
          <UserTypeContainer
            active={selectedType === role}
            id={`div-select-user-type-${role}`}
            key={role}
            onClick={selectType(role)}
            onMouseEnter={handleMouseEnter(role)}
            onMouseLeave={() => handleMouseLeave()}
          >
            {mouseOverType === role && widthApp > tabletWidth ? (
              <I18n tag={WhiteTitle} text={`users.jobRoles.${role}`} />
            ) : (
              <I18n text={`users.jobRoles.${role}`} />
            )}
            <UserTypeIcon>
              {
                /* eslint-disable */
                selectedType === role ? (
                  <IconContainer>
                    <Check2 />
                  </IconContainer>
                ) : mouseOverType === role && widthApp > tabletWidth ? (
                  <>
                    <I18n
                      color="white"
                      text={`users.rolesText.${role}`}
                      tag={Text}
                    />
                  </>
                ) : (
                  icon
                )
                /* eslint-enable */
              }
            </UserTypeIcon>
          </UserTypeContainer>
        ))}
      </TypesContainer>
      <NextButtonResponsive
        disabled={selectedType == null && !confirmation}
        id="btn-proceed-sign-up"
        onClick={handleContinue}
      >
        <I18n i18nKey="signUp.userType.cta" />
        <NextIcon />
      </NextButtonResponsive>
    </Container>
  )
}

export default SetOrganizationType
