import { appEnv, appRoot, devtools } from '@lyfta/components-config'
import { routerMiddleware } from 'connected-react-router/immutable'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { completeRehydration } from 'Store/Actions/app'
import rootReducer from 'Store/Reducers'
import rootSaga from 'Store/Sagas'

const transformerConfig = {}
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)],
  whitelist: ['auth', 'persist', 'viewer', 'prismic'],
}

export default (isSSR = false, path = '/') => {
  const history = isSSR
    ? createMemoryHistory({ initialEntries: [path] })
    : createBrowserHistory({ basename: appRoot })

  if (!isSSR) {
    history.listen(() => {
      const mainElem = document.getElementById('main')

      if (mainElem) {
        mainElem.scrollTop = 0
      }

      window.scrollTop = 0
    })
  }

  const middleware = [thunkMiddleware, routerMiddleware(history)]

  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  let enhancer = null

  if (isSSR) {
    enhancer = compose(applyMiddleware(...middleware))
  } else if (appEnv !== 'production' && devtools === 'true') {
    enhancer = compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : a => a,
    )
  } else if (
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.location.hash === '#magic.exe' &&
    devtools === 'true'
  ) {
    enhancer = compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : a => a,
    )
  } else {
    enhancer = applyMiddleware(...middleware)
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer(history))
  const store = createStore(persistedReducer, enhancer)

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    module.hot.accept(
      'Store/Reducers',
      () => store.replaceReducer(require('Store/Reducers').default), // eslint-disable-line global-require
    )
  }

  const persistor = persistStore(store, {}, () => {
    store.dispatch(completeRehydration())
  })
  return { store, persistor, history }
}
