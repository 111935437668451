import { AUTHENTICATE } from '@lyfta/components-data/src/Store/Actions/auth'
import { LOAD_VIEWER } from '@lyfta/components-data/src/Store/Actions/viewer'
import { push } from 'connected-react-router'
import paths from 'Constants/paths'
import { all, fork, put, take, takeEvery } from 'redux-saga/effects'

function* redirectAfterAuthentication() {
  yield take(LOAD_VIEWER.SUCCESS)
  yield put(push(paths.root))
}

export default function* root() {
  yield all([
    fork(takeEvery, AUTHENTICATE.SUCCESS, redirectAfterAuthentication),
  ])
}
