import * as React from 'react'

function SvgUsers(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M5 6C6.38096 6 7.5 7.11945 7.5 8.50001C7.5 9.88052 6.38099 11 5 11C3.61949 11 2.5 9.88056 2.5 8.50001C2.5 7.11949 3.61945 6 5 6Z" />
      <path d="M15 9.64062C15 7.9839 13.6572 6.64062 12 6.64062C10.3433 6.64062 9 7.9839 9 9.64062C9 11.2973 10.3432 12.6406 12 12.6406C13.6572 12.6406 15 11.2973 15 9.64062Z" />
      <path d="M17.6004 18.021C16.6986 15.4516 14.5308 13.6406 11.9994 13.6406C9.46804 13.6406 7.30016 15.4516 6.39844 18.021C7.84824 19.4219 9.82244 20 11.9994 20C14.1764 20 16.1506 19.4219 17.6004 18.021Z" />
      <path d="M1 16C2 17 2.625 17.222 4.43359 17.445L4.51188 17.222C5.08977 15.5755 6.11816 14.1055 7.46708 13.0683C6.65626 12.3957 5.66422 12 4.5928 12C2.5168 12 1 14 1 16Z" />
      <path d="M19 6C17.619 6 16.5 7.11945 16.5 8.50001C16.5 9.88052 17.619 11 19 11C20.3805 11 21.5 9.88056 21.5 8.50001C21.5 7.11949 20.3806 6 19 6Z" />
      <path d="M23 16C22 17 21.375 17.222 19.5664 17.445L19.4881 17.222C18.9102 15.5755 17.8818 14.1055 16.5329 13.0683C17.3437 12.3957 18.3358 12 19.4072 12C21.4832 12 23 14 23 16Z" />
    </svg>
  )
}

export default SvgUsers
