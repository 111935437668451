/* eslint-disable */

import { I18n } from '@lyfta/components-i18n'
import { AuthLayout, ButtonNew, Flex, SignInScreen } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getEmail } from 'Store/Selectors/signUp'

// import { loginDuringSignUp } from '@lyfta/components-data/src/Store/Actions/users'

const SignIn = () => {
  const email = useSelector(getEmail)

  useEffect(() => {
    document.title = 'Registration - Log in'
  }, [])

  const renderCustomButton = ({ handleSubmit }) => {
    return (
      <Flex width="100%" justifyContent="center">
        <ButtonNew
          id="btn-login"
          subtype="registration"
          mt={3}
          default={{
            minHeight: '40px',
          }}
          onClick={handleSubmit}
        >
          <I18n i18nKey="signUp.existingAccount.title" />
        </ButtonNew>
      </Flex>
    )
  }

  return (
    <AuthLayout>
      <SignInScreen
        customButton={renderCustomButton}
        paths={paths}
        withHeader
        withLinks
        initialValues={{ email }}
        // signInAction={loginDuringSignUp}
      />
    </AuthLayout>
  )
}

export default SignIn
