import { loadMeetingAttendeeByToken } from '@lyfta/components-data/src/Store/Actions/meetingAttendees'
import { getCurrentMeetingAttendee } from '@lyfta/components-data/src/Store/Selectors/meetingAttendees'
import { DateTime, I18n } from '@lyfta/components-i18n'
import { CalendarAlt, Clock, Flex, Globe2, Text } from '@lyfta/components-ui'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Heading1 } from '../../../../Components/Headings'
import { IconContainer } from '../../../../Components/IconContainer'
import { WebinarDetailsItem } from '../../WebinarBooking/Book/styles'
import { Container, ContainerDetails } from '../styles'
import { JoinControls } from './Join'
import { RegisterControls } from './Register'

/* eslint-disable react/jsx-no-literals */
const Register = () => {
  const { id: token } = useParams()
  const dispatch = useDispatch()

  const meetingAttendee = useSelector(getCurrentMeetingAttendee)
  const [scheduled, host, occurrence] = useMemo(() => {
    if (meetingAttendee) {
      return [
        meetingAttendee.attendanceStatus === 'scheduled',
        meetingAttendee?.occurrence?.host ||
          meetingAttendee?.meeting?.defaultHost,
        meetingAttendee.occurrence,
        meetingAttendee.meeting,
      ]
    }
    return [false, null, null, null]
  }, [meetingAttendee])

  useEffect(() => {
    dispatch(loadMeetingAttendeeByToken(token))
  }, [])

  useEffect(() => {
    document.title = 'Registration - Welcome to your Lyfta Webinar'
  }, [])

  return (
    <Flex flexDirection="column" width={1}>
      <I18n i18nKey="signUp.meetings.title" tag={Heading1} />
      <I18n
        color="primary"
        fontWeight="700"
        i18nKey="signUp.meetings.subTitle"
        options={{ host: host?.firstName }}
        tag={Text}
        textAlign="center"
      />

      <Container flexDirection="column" mb={[4, 7]} mt={[4, 7]}>
        <ContainerDetails>
          <WebinarDetailsItem>
            <Flex mr={3}>
              <IconContainer transparent>
                <Globe2 width={32} />
              </IconContainer>
            </Flex>
            <Text color="primary" fontWeight={700}>
              {meetingAttendee?.meeting?.name}
            </Text>
          </WebinarDetailsItem>
          <WebinarDetailsItem>
            <Flex mr={3}>
              <IconContainer transparent>
                <CalendarAlt width={32} />
              </IconContainer>
            </Flex>
            <Text color="primary" fontWeight={700}>
              {DateTime.fromISO(occurrence?.startDate, {
                setZone: true,
              }).toLocaleString(DateTime.DATE_HUGE)}
            </Text>
          </WebinarDetailsItem>
          <WebinarDetailsItem>
            <Flex mr={3}>
              <IconContainer transparent>
                <Clock width={32} />
              </IconContainer>
            </Flex>
            <Text color="primary" fontWeight={700}>
              {DateTime.fromISO(occurrence?.startDate, {
                setZone: false,
              }).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
            </Text>
          </WebinarDetailsItem>
        </ContainerDetails>
      </Container>

      {!isEmpty(meetingAttendee) && scheduled ? (
        <WebinarDetailsItem>
          <RegisterControls meetingAttendee={meetingAttendee} />
        </WebinarDetailsItem>
      ) : (
        !isEmpty(meetingAttendee) && (
          <WebinarDetailsItem>
            <JoinControls meetingAttendee={meetingAttendee} />
          </WebinarDetailsItem>
        )
      )}
    </Flex>
  )
}

export default Register
