import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import {
  AnimatedInputNew,
  ButtonNew,
  Checkbox,
  Flex,
  FormColumnLayout,
} from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { getProcessing } from '../../../../../../Store/Selectors/signUp'
import { NextIcon } from '../../../styles'
import { CheckboxLabel, LinkPrivacy } from '../styles'

// eslint-disable-next-line react/prop-types
const SignUpForm = ({ handleSubmit, form, invalid }) => {
  const processing = useSelector(getProcessing)

  const onPasswordInput = e => {
    form.mutators.setValue('passwordConfirmation', e.target.value)
  }
  return (
    <>
      <FormColumnLayout>
        <AnimatedInputNew
          autoFocus
          description=""
          id="input-first-name"
          label={<I18n text="fields.firstName" />}
          mb={3}
          name="firstName"
        />
        <AnimatedInputNew
          description=""
          id="input-last-name"
          label={<I18n text="fields.lastName" />}
          mb={3}
          name="lastName"
        />
      </FormColumnLayout>
      <AnimatedInputNew
        description=""
        id="input-email"
        label={<I18n text="fields.email" />}
        mb={3}
        name="email"
        type="email"
      />
      <AnimatedInputNew
        autoComplete="off"
        id="input-password"
        label={<I18n text="fields.password" />}
        mb={3}
        name="password"
        type="password"
        onChange={onPasswordInput}
      />

      <Checkbox
        id="checkbox-consent-privacy-policy"
        mb={2}
        mt={2}
        name="privacyPolicy"
      />
      <CheckboxLabel>
        <Flex color="primary" display="inline">
          <TransLink
            components={{
              Link: (
                <LinkPrivacy
                  href={getTranslation('signUp.links.termsAndConditions')}
                  target="_blank"
                  to={getTranslation('signUp.links.termsAndConditions')}
                />
              ),

              Link2: (
                <LinkPrivacy
                  href={getTranslation('signUp.links.privacyPolicy')}
                  target="_blank"
                  to={getTranslation('signUp.links.privacyPolicy')}
                />
              ),
            }}
            i18nKey="signUp.acceptTermPrivacy"
            values={{ what: 'world' }}
          />
        </Flex>
      </CheckboxLabel>

      <Flex alignItems="center" justifyContent="center" width={1}>
        <ButtonNew
          disabled={processing || invalid}
          id="btn-proceed-sign-up"
          mt={4}
          subtype="registration"
          onClick={handleSubmit}
          disabledStyle={{ opacity: 0.7 }}
        >
          <I18n
            i18nKey={
              processing ? 'signUp.processing' : 'signUp.setPassword.cta'
            }
          />
          <NextIcon />
        </ButtonNew>
      </Flex>
    </>
  )
}

SignUpForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default SignUpForm
