import { createSelector } from 'reselect'

import { getAllEntities, getEntity, getEntityStateField } from '../../utils'

export const getMeetingAttendees = getAllEntities('meetingAttendees')
export const getCurrentMeetingAttendee = getEntity('meetingAttendees')

export const getMeetingAttendeesByOccurenceId = occurrenceId =>
  createSelector(getMeetingAttendees, data =>
    data.filter(value => value.occurrence?.id === occurrenceId),
  )

export const getIsLoaded = getEntityStateField('meetingAttendees', 'isLoaded')
export const getIsLoading = getEntityStateField('meetingAttendees', 'isLoading')
export const getPaged = getEntityStateField('meetingAttendees', 'paged')
