import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import {
  BREAKPOINTS,
  Flex,
  Text,
  css,
  styled,
  themeGet,
} from '@lyfta/components-ui'

export const PlanCards = styled(Flex).attrs(() => ({
  flexDirection: ['column', 'row'],
}))`
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-top: 1rem;
  }
`

export const PlanCard = styled(Flex).attrs(height => ({
  flexDirection: 'column',
  width: 220,
  height: height || '275px',
  alignItems: 'center',
  justifyContent: 'center',
}))`
  ${themeGet('buttonShadow.default')};
  background-color: ${themeGet('colors.black')}10;

  border-radius: 20px;

  ${({ neutral }) =>
    neutral &&
    css`
      background-color: ${themeGet('colors.black')}10;
    `};

  ${({ accent }) =>
    accent &&
    css`
      background-color: ${themeGet('colors.primary')}80;
      color: ${themeGet('colors.white')};
    `};

  ${({ dark }) =>
    dark &&
    css`
      background-color: ${themeGet('colors.primary')};
      color: ${themeGet('colors.white')};
      cursor: pointer;
    `};
  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
    `};
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    ${({ transparent }) =>
      transparent &&
      css`
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        border: 1px solid ${themeGet('colors.primary')};
        margin-top: 1rem;
      `};
  }
`

PlanCard.defaultProps = {
  p: 4,
}

export const PlanFeatures = styled(Flex).attrs(() => ({
  display: ['none', 'flex'],
  flexDirection: 'column',
  alignSelf: 'center',
  width: 220,
}))`
  color: ${themeGet('colors.primary')};
`

export const PlanFeature = styled(Flex).attrs(() => ({
  borderColor: 'primary',
  py: 3,
  borderWidth: 1,
  width: '100%',
}))`
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    border: none;
  }
`

export const IconContainer = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))`
  font-size: 20px;

  ${({ dark }) =>
    dark &&
    css`
      svg,
      path {
        fill: ${themeGet('colors.white')};
      }
    `};
  ${({ transparent }) =>
    transparent &&
    css`
      svg,
      path {
        fill: ${themeGet('colors.primary')};
      }
    `};
`

export const FeatureText = styled(Text).attrs(() => ({
  fontSize: 18,
  fontWeight: 400,
}))``

// export const ButtonSelect = styled(Flex).attrs(() => ({}))`
//   border-radius: 35px;
//   font-family: Helvetica;
//   letter-spacing: 1px;
//   text-align: center;
// `
export const ButtonSelected = styled(Flex).attrs(() => ({}))`
  background: #4d5fa3;
  border-radius: 35px;
  font-family: Helvetica;
  font-size: 20px;
  color: #fafafa;
  letter-spacing: 1px;
  width: 100px;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
`

export const ButtonNext = styled(Flex).attrs(() => ({}))`
  display: flex;
  justify-content: center;
  align-item: center;
`
