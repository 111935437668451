import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { I18n } from '@lyfta/components-i18n'
import { Flex, PropTypes, openInTeacherApp } from '@lyfta/components-ui'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { NextButton } from '../../../../../Components/NextButton'

export const JoinControls = ({ meetingAttendee: { meeting } }) => {
  const launchZoom = () => window.open(meeting.zoomLink)
  const jwt = useSelector(getJWT)

  useEffect(() => {
    document.title = 'Registration - Webinar join'
  }, [])

  return (
    <Flex flexDirection={['column', 'row']} justifyContent="center" width={1}>
      <NextButton mb={[4, 0]} mr={[0, 4]} secondary onClick={launchZoom}>
        <I18n
          mb={3}
          // as={Button}
          text="meetings.register.access"
        />
      </NextButton>
      <NextButton onClick={openInTeacherApp({ jwt })}>
        <I18n i18nKey="meetings.register.openLyfta" tag={React.Fragment} />
      </NextButton>
    </Flex>
  )
}

JoinControls.propTypes = {
  meetingAttendee: PropTypes.object.isRequired,
}
