import paths from 'Constants/paths'
import ChildrenAccount from 'Containers/Pages/SignUp/CreateAccount/ChildrenAccount'
import CreateAccount from 'Containers/Pages/SignUp/CreateAccount/CreateAccount'
// import CreateParentAccount from 'Containers/Pages/SignUp/CreateAccount/CreateParentAccount'
import Invitation from 'Containers/Pages/SignUp/CreateAccount/Invitation'
import OrganizationSetup from 'Containers/Pages/SignUp/CreateAccount/OrganizationSetup'
import OrganizationType from 'Containers/Pages/SignUp/CreateAccount/OrganizationType'
import SignIn from 'Containers/Pages/SignUp/CreateAccount/SignIn'
import UserType from 'Containers/Pages/SignUp/CreateAccount/UserType'
import Welcome from 'Containers/Pages/SignUp/Welcome'
import React from 'react'
import { Redirect } from 'react-router-dom'

import SignUpLayout from '../../Containers/Layout/SignUp'
import ParentResources from '../../Containers/Pages/SignUp/CreateAccount/ParentResources'

export default [
  {
    path: paths.signUp.root,
    component: SignUpLayout,
    routes: [
      {
        path: paths.signUp.createAccount.userType,
        exact: true,
        component: UserType,
      },
      {
        path: paths.signUp.createAccount.organizationType,
        exact: true,
        component: OrganizationType,
      },
      {
        path: paths.signUp.createAccount.organization,
        exact: true,
        component: OrganizationSetup,
      },
      {
        path: paths.signUp.createAccount.parentResources,
        exact: true,
        component: ParentResources,
      },
      {
        path: paths.signUp.createAccount.childrenAccount,
        exact: true,
        component: ChildrenAccount,
      },
      {
        path: paths.signUp.createAccount.createUserAccount,
        exact: true,
        component: CreateAccount,
      },
      // {
      //   path: paths.signUp.createAccount.createParentAccount,
      //   exact: true,
      //   component: CreateParentAccount,
      // },
      {
        path: paths.signUp.createAccount.login,
        exact: true,
        component: SignIn,
      },
      {
        path: paths.signUp.acceptInvite,
        exact: true,
        component: Invitation,
      },
      {
        render: () => <Redirect to={paths.signUp.welcome} />,
      },
    ],
  },
  {
    component: Welcome,
  },
]
