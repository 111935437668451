/* eslint-disable */

import { CoreStore } from '@lyfta/components-data'
import { signUpOrganization } from '@lyfta/components-data/src/Store/Actions/organizations'
import {
  CREATE_PARENT_USER,
  createParent,
} from '@lyfta/components-data/src/Store/Actions/users'
import { setEmail as setEmailViewer } from '@lyfta/components-data/src/Store/Actions/viewer'
import { getOrganization } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewerId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { showNotification } from '@lyfta/components-ui/src/Services/Utils'
import { push } from 'connected-react-router'
import paths from 'Constants/paths'
import { get } from 'lodash'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import { setProcessing } from '../Actions/signUp'

const {
  Auth: authActions,
  Users: userActions,
  Viewer: viewerActions,
} = CoreStore.Actions
const { Viewer: viewerSelectors } = CoreStore.Selectors

const { signIn } = authActions
const { loadViewer } = viewerActions
const { getViewer } = viewerSelectors
const {
  CREATE_USER,
  CREATE_CHILD,
  createUser,
  createChild,
  UPDATE_USER,
  updateUser,
  SIGN_IN_DURING_SIGNUP,
} = userActions

function* createAndLoginUser({ payload: { user } }) {
  yield put(setProcessing(true))
  let response
  const organization = yield yield select(getOrganization)
  yield yield put(setEmailViewer(user.email, false))
  if (user.role === 'parent') {
    response = yield yield put(createParent(user))
  }
  if (user.role === 'teacher') {
    response = yield yield put(createUser(user))
  }

  if (response.ok) {
    const loginResponse = yield yield put(signIn(user.email, user.password))
    if (loginResponse.ok) {
      // eslint-disable-next-line
      const responseOrganization = yield yield put(
        signUpOrganization(organization),
      )

      if (user.role === 'parent') {
        // yield put(push(paths.signUp.createAccount.parentResources))
        yield put(push(paths.signUp.createAccount.childrenAccount))
      }
      if (user.role === 'teacher') {
        yield put(push(paths.signUp.createAccount.settingAccount))
      }
    }
  }

  yield put(setProcessing(false))
}

function* createChildren({
  payload: {
    user: { children },
  },
}) {
  yield put(setProcessing(true))
  let response

  // TODO API ACCEPT ARRAYS?
  for (const child of children) {
    try {
      const newChild = {
        ...child,
        password_confirmation: child.password,
        role: 'home_student',
      }

      response = yield yield put(createChild(newChild))
    } catch (error) {
      showNotification(getTranslation('auth.somethingWrong'), 'error')
    }
  }

  if (response.ok) {
    yield put(push(paths.signUp.createAccount.done))
  } else {
    showNotification(
      response.error.response.body.errors[0].detail.message,
      'error',
    )
  }
  yield put(setProcessing(false))
}

function* updateUserLogin({
  payload: {
    user: { whereDidYouHearAboutUs },
  },
}) {
  yield put(setProcessing(true))

  const userId = yield yield select(getViewerId)
  const response = yield yield put(
    updateUser({ id: userId, whereDidYouHearAboutUs }),
  )

  if (response.ok) {
    yield put(push(paths.signUp.createAccount.done))
  }
  yield put(push(paths.signUp.createAccount.done))

  yield put(setProcessing(false))
}

function* redirectBasedOnOrganizationType(viewer) {
  const { organization, jobRole, meetingToken, meetingId } = viewer
  if (organization && !organization.isSignUpOrg) {
    const licensings = get(organization, 'licensings', [])

    if (licensings.length === 0) {
      yield put(push(paths.signUp.createAccount.plan))
      return true
    }

    const { license } = licensings[0]

    if (license && license.suggestedMeeting) {
      yield put(
        push(paths.webinarBooking.register(license.suggestedMeeting.id)),
      )
      return true
    }
  }

  if (jobRole === null) {
    yield put(push(paths.signUp.createAccount.userType))
    return true
  }
  if (organization.name === 'Lyfta - Sign Up Organization') {
    yield put(push(paths.signUp.createAccount.organization))
    return true
  }

  if (meetingToken) {
    yield put(push(paths.webinarBooking.join.book(meetingToken, { meetingId })))
    return true
  }

  return false
}

function* signinDuringSignUp({ email, password }) {
  const response = yield yield put(signIn(email, password))
  if (response.ok) {
    const viewerResponse = yield yield put(loadViewer())
    if (viewerResponse.ok) {
      const viewer = yield yield select(getViewer)

      const redirected = yield redirectBasedOnOrganizationType(viewer)

      if (!redirected) yield put(push(paths.signUp.createAccount.done))
      return false
    }
  }
  return false
}

export default function* root() {
  yield all([
    takeLatest(CREATE_USER.REQUEST_PERFORM, createAndLoginUser),
    takeLatest(CREATE_PARENT_USER.REQUEST_PERFORM, createAndLoginUser),
    takeLatest(CREATE_CHILD.REQUEST_PERFORM, createChildren),
    takeLatest(UPDATE_USER.REQUEST_PERFORM, updateUserLogin),
    takeLatest(SIGN_IN_DURING_SIGNUP, signinDuringSignUp),
  ])
}
