import React from 'react'

import { Container } from '../../styles'

/* eslint-disable react/jsx-no-literals */
const ParentSetup = () => {
  return <Container>CAPTURE PARENT DETAILS</Container>
}

export default ParentSetup
