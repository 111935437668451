import 'I18n'

import {
  bingTrackingRegistrationId,
  googleAnalyticsRegistrationId,
} from '@lyfta/components-config'
import { isAuthenticated } from '@lyfta/components-data/src/Store/Selectors/auth'
import { AppContainer } from '@lyfta/components-ui'
import RoutesConfig from 'Config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { renderRoutes } from 'react-router-config'

const App = ({ history }) => {
  const authenticated = useSelector(isAuthenticated)
  return (
    <AppContainer
      bingTrackingId={bingTrackingRegistrationId}
      googleAnalyticsId={googleAnalyticsRegistrationId}
      history={history}
      withAnalytics
      withBingTracking
      withHubspot
      withIntercom
    >
      {renderRoutes(RoutesConfig.build(authenticated))}
    </AppContainer>
  )
}

App.propTypes = {
  history: PropTypes.object.isRequired,
}

export default App
