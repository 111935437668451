import { sendInvites } from '@lyfta/components-data/src/Store/Actions/meetingInvites'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { Flex, Remove, Text } from '@lyfta/components-ui'
import { emailConstraint } from '@lyfta/components-ui/src/Constants/constraints'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import uniq from 'lodash/uniq'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import validate from 'validate.js'

import { Heading1 } from '../../../../Components/Headings'
import { HeartIcon, NextButton } from '../styles'
import inviteFormFields from './form'
import { Container, Divider, FAB, FormContainer, TeacherEmail } from './styles'

const initialValues = {
  email: '',
}

const handleEmailEntry = (__, state, utils) => {
  const email = state?.formState?.values?.email
  utils.changeValue(state, 'email', () => trim(email?.toLowerCase()))
}

const validateForm = values => {
  return validate(values, { ...emailConstraint })
}

/* eslint-disable max-lines-per-function */
function Invite() {
  const { meetingId, id: occurrenceId } = useParams()

  const dispatch = useDispatch()

  const viewer = useSelector(getViewer)

  const viewerId = viewer?.id
  const organizationId = viewer?.organization?.id

  const [teachers, setTeachers] = useState([])

  const addTeacher = values => {
    setTeachers(prevTeachers => uniq([...prevTeachers, values.email]))
  }

  const removeTeacher = index => () => {
    setTeachers(prevTeachers => {
      prevTeachers.splice(index, 1)
      return [...prevTeachers]
    })
  }

  const inviteTeachers = () => {
    const payload = teachers.map(teacherEmail => ({
      meetingId,
      userId: viewerId,
      organizationId,
      teacherEmail,
      meetingOccurrenceId: occurrenceId,
    }))

    dispatch(sendInvites(payload))
  }

  useEffect(() => {
    document.title = 'Registration - Invite your colleagues'
  }, [])

  return (
    <Container>
      <Flex mb={[5]}>
        <I18n
          fontSize={[5, 7]}
          fontWeight={700}
          i18nKey="invite.title"
          lineHeight={7}
          tag={Heading1}
        />
      </Flex>
      <FormContainer mb={5} width={1}>
        <Form
          initialValues={initialValues}
          mutators={{ handleEmailEntry }}
          render={inviteFormFields}
          validate={validateForm}
          onSubmit={addTeacher}
        />
      </FormContainer>
      <Flex flexDirection="column" mb={[8, 10]} width={1}>
        <I18n
          color="primary"
          fontWeight={700}
          i18nKey="invite.teachersAdded"
          tag={Text}
        />
        <Divider />
        <Flex flexDirection="column">
          {teachers.map((email, index, array) => {
            return (
              <TeacherEmail key={email} mb={index === array.length - 1 ? 0 : 2}>
                <Text color="primary">{email}</Text>
                <FAB ml={3} onClick={removeTeacher(index)}>
                  <Remove />
                </FAB>
              </TeacherEmail>
            )
          })}
        </Flex>
      </Flex>
      <NextButton disabled={isEmpty(teachers)} onClick={inviteTeachers}>
        <I18n tag={React.Fragment} text="invite.cta" />
        <HeartIcon />
      </NextButton>
    </Container>
  )
}

export default Invite
