import { Flex, styled } from '@lyfta/components-ui'

export const Actions = styled(Flex).attrs(() => ({
  width: '100%',
  flexDirection: ['column-reverse', 'row'],
  mt: [5, 10],
}))`
  align-items: center;
  justify-content: center;
`
