import { WebinarCalendar } from 'Components/Webinar/Calendar'
import paths from 'Constants/paths'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

/* eslint-disable react/jsx-no-literals */
const SelectDate = () => {
  const { id: meetingId } = useParams()
  const history = useHistory()
  const navigateToConfirm = selectedOccurrence => () => {
    history.push(
      paths.webinarBooking.book(selectedOccurrence.id, { meetingId }),
    )
  }

  return (
    <WebinarCalendar
      meetingId={meetingId}
      navigateToConfirm={navigateToConfirm}
    />
  )
}

export default SelectDate
