import { AuthLayout } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React from 'react'
import { renderRoutes } from 'react-router-config'

import SignUpLayout from '../../Containers/Layout/SignUp'
import WebinarRegistrationLayout from '../../Containers/Layout/WebinarRegistration'
import { SignIn } from '../../Containers/Pages/Auth'
import BookOnWebinar from '../../Containers/Pages/WebinarBooking/Book'
import Invite from '../../Containers/Pages/WebinarBooking/Invite'
import SelectDate from '../../Containers/Pages/WebinarBooking/SelectDate'
import Register from '../../Containers/Pages/WebinarRegistration/Register'
import Reschedule from '../../Containers/Pages/WebinarRegistration/Reschedule'

export default [
  {
    path: paths.webinarBooking.reschedule.root(),
    component: WebinarRegistrationLayout,
    routes: [
      {
        path: paths.webinarBooking.reschedule.book(),
        component: Reschedule,
      },
      {
        path: paths.webinarBooking.reschedule.complete(),
        component: BookOnWebinar,
      },
    ],
  },
  {
    path: paths.webinarBooking.join.root(),
    // eslint-disable-next-line react/prop-types
    render: ({ route }) => (
      <AuthLayout containerFlex={[3, 1]}>
        {/* eslint-disable-next-line react/prop-types */}
        {renderRoutes(route.routes)}
      </AuthLayout>
    ),
    routes: [
      {
        path: paths.webinarBooking.join.book(),
        exact: true,
        component: Register,
      },
    ],
  },
  {
    path: paths.webinarBooking.root(),
    component: SignUpLayout,
    routes: [
      {
        path: paths.webinarBooking.register(),
        exact: true,
        component: SelectDate,
      },
      {
        path: paths.webinarBooking.book(),
        exact: true,
        component: BookOnWebinar,
      },
      {
        path: paths.webinarBooking.invite(),
        exact: true,
        component: Invite,
      },
      {
        component: SignIn,
      },
    ],
  },
]

export const publicWebinarRegistration = [
  {
    path: [
      paths.webinarBooking.join.root(),
      paths.webinarBooking.reschedule.root(),
    ],
    render: ({ route }) => renderRoutes(route.routes),
    routes: [
      {
        component: SignIn,
      },
    ],
  },
]
