import { loadMeetingInviteByToken } from '@lyfta/components-data/src/Store/Actions/meetingInvites'
import { getCurrentMeetingInvite } from '@lyfta/components-data/src/Store/Selectors/meetingInvites'
import { I18n } from '@lyfta/components-i18n'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { acceptInvite, setInviteToken } from 'Store/Actions/signUp'

import {
  Container,
  Heading1,
  Heading2,
  NextButton,
  NextIcon,
} from '../../styles'

const AcceptInvite = () => {
  const { token } = useParams()
  const dispatch = useDispatch()
  const meetingInvite = useSelector(getCurrentMeetingInvite)

  const continueSignUp = () => {
    dispatch(acceptInvite(meetingInvite))
  }

  useEffect(() => {
    dispatch(setInviteToken(token))
    dispatch(loadMeetingInviteByToken(token))
  }, [])

  useEffect(() => {
    document.title = 'Registration - Invite others'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.inviteAccept.title" tag={Heading1} />
      <I18n i18nKey="signUp.inviteAccept.subTitle" tag={Heading2} />

      <NextButton disabled={isEmpty(meetingInvite)} onClick={continueSignUp}>
        <I18n i18nKey="signUp.userType.cta" />
        <NextIcon />
      </NextButton>
    </Container>
  )
}

export default AcceptInvite
