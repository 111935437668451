import { I18n } from '@lyfta/components-i18n'
import { Check2, Close2, Flex, Text } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import {
  ButtonSelected,
  FeatureText,
  IconContainer,
  PlanCard,
  PlanCards,
  PlanFeature,
  PlanFeatures,
} from './styles'

/* eslint-disable max-lines-per-function */
function NoWebinarPlan(props) {
  const { onSetWebinarPlan } = props
  return (
    <PlanCards>
      <PlanFeatures>
        <I18n
          i18nKey="signUp.plan.webinar.title"
          style={{
            color: 'white',
            letterSpacing: '3.75px',
            marginBottom: '5px',
            fontWeight: 700,
          }}
          tag={FeatureText}
          textTransform="uppercase"
        />
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          marginBottom={1}
          paddingLeft="1rem"
        >
          <I18n i18nKey="signUp.plan.features.freeFor" tag={FeatureText} />
        </PlanFeature>
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          marginBottom={1}
          paddingLeft="1rem"
        >
          <I18n
            i18nKey="signUp.plan.features.numberStoryworlds"
            tag={FeatureText}
          />
        </PlanFeature>
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          paddingLeft="1rem"
        >
          <I18n i18nKey="signUp.plan.features.webinar" tag={FeatureText} />
        </PlanFeature>
      </PlanFeatures>

      <PlanCard
        color="primary"
        mb={[4, 0]}
        p={0}
        py={4}
        transparent
        width={1}
        onClick={() => {
          onSetWebinarPlan(true)
        }}
      >
        <I18n
          i18nKey="signUp.plan.webinar.title"
          style={{
            letterSpacing: '3.75px',
            marginBottom: '5px',
            fontWeight: 700,
          }}
          tag={FeatureText}
          textTransform="uppercase"
        />
        <PlanFeature
          alignItem="center"
          borderBottom="solid"
          borderTop="solid"
          justifyContent="center"
          marginBottom={1}
        >
          <I18n i18nKey="signUp.plan.webinar.freeFor" tag={FeatureText} />
        </PlanFeature>
        <PlanFeature
          alignItem="center"
          borderBottom="solid"
          borderTop="solid"
          justifyContent="center"
          marginBottom={1}
        >
          <I18n
            i18nKey="signUp.plan.webinar.numberStoryworlds"
            tag={FeatureText}
          />
        </PlanFeature>
        <PlanFeature
          alignItem="center"
          borderBottom="solid"
          borderTop="solid"
          justifyContent="center"
        >
          <IconContainer transparent>
            <Check2 fontSize="1rem" textAlign="center" width="100%" />
          </IconContainer>
        </PlanFeature>
      </PlanCard>

      <PlanCard color="primary" dark height="268px">
        <I18n
          i18nKey="signUp.plan.noWebinar.title"
          style={{
            letterSpacing: '3.75px',
            marginBottom: '5px',
            fontWeight: 700,
          }}
          tag={FeatureText}
          textTransform="uppercase"
        />
        <Flex flexDirection="column" width={1}>
          <PlanFeature>
            <I18n
              i18nKey="signUp.plan.noWebinar.freeFor"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>
          <PlanFeature>
            <I18n
              i18nKey="signUp.plan.noWebinar.numberStoryworlds"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>
          <PlanFeature>
            <IconContainer dark>
              <Close2 fontSize="1.2rem" textAlign="center" width="100%" />
            </IconContainer>
          </PlanFeature>
        </Flex>
        <ButtonSelected>
          <I18n
            i18nKey="signUp.plan.buttonSelected"
            tag={Text}
            textTransform="uppercase"
          />
        </ButtonSelected>
      </PlanCard>
    </PlanCards>
  )
}

NoWebinarPlan.propTypes = {
  onSetWebinarPlan: PropTypes.object.isRequired,
}

export default NoWebinarPlan
