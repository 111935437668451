import { generateSystemPath } from '@lyfta/components-ui'

const paths = {
  root: '/',
  signUp: {
    root: '/sign-up',
    welcome: '/welcome',
    acceptInvite: '/sign-up/invite/:token',
    createAccount: {
      createUserAccount: '/sign-up/set-password',
      // createParentAccount: '/sign-up/create-parent-account',
      childrenAccount: '/sign-up/create-children',
      settingAccount: '/sign-up/setting-account',
      userType: '/sign-up/user-type',
      organizationType: '/sign-up/organization-type',
      organization: '/sign-up/organization',
      parentResources: '/sign-up/parent-resources',
      plan: '/sign-up/plan',
      confirmSkip: '/sign-up/confirm-skip',
      existingOrganization: '/sign-up/organization/existing',
      parentSetup: '/sign-up/parent',
      login: '/sign-up/continue',
      complete: '/sign-up/complete',
      userCount: '/sign-up/teachers',
      done: '/sign-up/done',
      invitesSent: '/sign-up/invites-sent',
    },
  },
  webinarBooking: {
    root: generateSystemPath('meetings', true),
    register: generateSystemPath('meetings', true, false, 'register'),
    join: {
      root: generateSystemPath(['meetings', 'join']),
      book: generateSystemPath(['meetings', 'join'], true),
    },
    book: generateSystemPath(['meetings', 'attendance'], true, false, 'book'),
    invite: generateSystemPath(
      ['meetings', 'attendance'],
      true,
      false,
      'invite',
    ),
    reschedule: {
      root: generateSystemPath(['meetings', 'reschedule']),
      book: generateSystemPath(['meetings', 'reschedule'], true, false, 'book'),
      complete: generateSystemPath(
        ['meetings', 'reschedule'],
        true,
        false,
        'complete',
      ),
    },
  },
  signIn: '/sign-in',
  forgot: '/forgot-password',
  changePassword: '/change-password',
}

export default paths
