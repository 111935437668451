import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const SET_PROCESSING = 'signUp/SET_PROCESSING'
export const ACCEPT_INVITE = 'signUp/ACCEPT_INVITE'
export const SET_EMAIL = 'signUp/SET_EMAIL'
export const SET_INVITE_TOKEN = 'signUp/SET_INVITE_TOKEN'
export const SET_ORGANIZATION_TEACHER_COUNT =
  'signUp/SET_ORGANIZATION_TEACHER_COUNT'
export const SELECT_PLAN = 'signUp/SELECT_PLAN'
export const CONFIRM_SKIP = 'signUp/CONFIRM_SKIP'

export const SET_ADDRESS = 'signUp/SET_ADDRESS'
export const SET_TYPE = 'signUp/SET_TYPE'
// export const SKIP_WEBINAR = 'signUp/SKIP_WEBINAR'
export const JOIN_INVITED_SCHOOL = 'signUp/JOIN_SCHOOL'
export const VALIDATE_ADDRESS = createAsyncAction('signUp/VALIDATE_ADDRESS')

export const acceptInvite = meetingAttendee => ({
  type: ACCEPT_INVITE,
  meetingAttendee,
})
export const setInviteToken = inviteToken => ({
  type: SET_INVITE_TOKEN,
  inviteToken,
})

export const joinInvitedSchool = () => ({
  type: JOIN_INVITED_SCHOOL,
})

export const selectPlan = () => ({
  type: SELECT_PLAN,
})

export const confirmSkip = () => ({
  type: CONFIRM_SKIP,
})

export const setEmail = (email, firstName, lastName, source) => {
  return {
    type: SET_EMAIL,
    email,
    firstName,
    lastName,
    source,
  }
}

export const setProcessing = processing => ({
  type: SET_PROCESSING,
  processing,
})

export const setOrganizationTeacherCount = count => ({
  type: SET_ORGANIZATION_TEACHER_COUNT,
  count,
})

export const setSignUpType = signUpType => ({
  type: SET_TYPE,
  signUpType,
})

export const setAddress = address => ({
  type: SET_ADDRESS,
  address,
})

export const validateAddress = ({ email } = {}) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/validate_addresses',
    query: {
      data: {
        type: 'validateAddresses',
        attributes: {
          email,
        },
      },
    },
    paged: true,
    types: VALIDATE_ADDRESS,
  })
