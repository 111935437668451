import { I18n } from '@lyfta/components-i18n'
import {
  AnimatedTextAreaNew,
  ButtonNew,
  Flex,
  Text,
} from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { getProcessing } from '../../../../../../Store/Selectors/signUp'
import { DescriptionText, NextIcon, SectionHeader } from '../../../styles'

// eslint-disable-next-line react/prop-types
const SignUpForm = ({ handleSubmit, invalid }) => {
  const processing = useSelector(getProcessing)

  return (
    <>
      <DescriptionText>
        <I18n
          color="slateGrey"
          fontSize={4}
          lineHeight={3}
          tag={Text}
          text="signUp.settingAccount.description"
          textAlign="center"
        />
      </DescriptionText>
      <I18n tag={SectionHeader} text="signUp.settingAccount.subTitle" />
      <AnimatedTextAreaNew
        description=""
        inputId="input-where-did-you-hear"
        name="whereDidYouHearAboutUs"
        rows={1}
      />
      <Flex alignItems="center" justifyContent="center" mt={5} width={1}>
        <ButtonNew
          disabled={processing || invalid}
          id="btn-proceed-sign-up"
          onClick={handleSubmit}
          subtype="registration"
          className="hs-cta-trigger-button hs-cta-trigger-button-119199873865"
          disabledStyle={{ opacity: 0.7 }}
        >
          <I18n
            i18nKey={
              processing ? 'signUp.processing' : 'signUp.settingAccount.cta'
            }
          />
          <NextIcon />
        </ButtonNew>
      </Flex>
    </>
  )
}

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default SignUpForm
