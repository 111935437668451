import * as React from 'react'

function SvgKey(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 98 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.805 50.119c6.238-6.273 15.152-8.228 23.055-5.876l33.275-33.456L88 10l-2.347 19.666h-9.388v9.44h-9.389v9.44l-11.368 11.43c2.339 7.947.395 16.91-5.844 23.181-9.073 9.124-23.785 9.124-32.859 0-9.073-9.123-9.073-23.915 0-33.038zm6.099 17.785a6.5 6.5 0 109.192 9.192 6.5 6.5 0 00-9.192-9.192z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgKey
