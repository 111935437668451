import { updateMeetingAttendee } from '@lyfta/components-data/src/Store/Actions/meetingAttendees'
import { getCurrentMeetingAttendee } from '@lyfta/components-data/src/Store/Selectors/meetingAttendees'
import { WebinarCalendar } from 'Components/Webinar/Calendar'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const Reschedule = () => {
  const { meetingId } = useParams()
  const { id } = useSelector(getCurrentMeetingAttendee)

  const dispatch = useDispatch()
  const history = useHistory()
  const navigateToConfirm = selectedOccurrence => () => {
    dispatch(
      updateMeetingAttendee({
        meetingId,
        id,
        attendanceStatus: 'rescheduled',
      }),
    )
    history.push(
      paths.webinarBooking.reschedule.complete(selectedOccurrence.id, {
        meetingId,
      }),
    )
  }

  useEffect(() => {
    document.title = 'Registration - Webinar reschedule'
  }, [])

  return (
    <WebinarCalendar
      meetingId={meetingId}
      navigateToConfirm={navigateToConfirm}
    />
  )
}

export default Reschedule
