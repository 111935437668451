import { BREAKPOINTS } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import {
  ButtonNew,
  Flex,
  HeartLight,
  Next,
  Text,
  css,
  styled,
  themeGet,
} from '@lyfta/components-ui'
import React from 'react'

export const NextButton = styled(ButtonNew).attrs(() => ({
  width: ['100%', 330],
  borderRadius: '100vw',
  height: 50,
  fontWeight: 700,
  shadow: true,
  type: 'button',
}))`
  background-color: ${themeGet('colors.primary')};
  justify-content: center;

  ${({ secondary }) =>
    secondary &&
    css`
      opacity: 0.4;
      background: white;
      color: ${themeGet('colors.primary')};

      &:hover {
        color: white;
        background: ${themeGet('colors.primary')};
      }
    `};
`

NextButton.defaultProps = {
  fontSize: [3, 5],
}

export const NextIcon = styled(Flex).attrs(() => ({
  children: <Next />,
  minWidth: 32,
  minHeight: 32,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  opacity: 0.3;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 1.5em;
`

export const Container = styled(Flex).attrs(() => ({
  alignSelf: ['unset', 'center'],
}))`
  flex-direction: column;
  justify-self: center;
  align-items: center;
  height: 100%;
  width: 900px;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    width: 100%;
  }
`

export const Heading1 = styled(Text).attrs(() => ({
  as: 'h1',
  fontSize: [5, 6, 7],
  lineHeight: [5, 6, 7],
  color: 'primary',
}))`
  text-align: center;
`

Heading1.defaultProps = {
  fontWeight: [600, 700],
  mb: [2, 5],
}

export const Heading2 = styled(Heading1).attrs(() => ({
  as: 'h2',
  fontSize: [2, 4, 5],
  lineHeight: [2, 4, 5],
  pt: [2, 3, 4],
  px: [2, 3, 7],
}))`
  font-family: Helvetica;
`

Heading2.defaultProps = {
  mb: [3, 5],
}

export const HeartIcon = styled(Flex).attrs(() => ({
  children: <HeartLight height="32px" width="32px" />,
  minWidth: 32,
  minHeight: 32,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  background-color: ${themeGet('colors.primary')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`

export const DescriptionText = styled(Flex).attrs(() => ({
  mt: 3,
  mb: 5,
}))`
  color: ${themeGet('colors.lightGray')};
`

export const UlContainer = styled.ul`
  text-align: left;
`
export const LiContainer = styled.li`
  margin-bottom: 15px;
  margin-right: 20px;
  list-style-type: none;
`
export const SectionHeader = styled(Text).attrs(() => ({
  level: 3,
  mt: 3,
  mb: 3,
  ml: 0,
  mr: 0,
}))`
  color: ${themeGet('colors.primary')};
  line-height: 20px;
  font-size: 20px;
`
