import PropTypes from 'prop-types'
import React from 'react'

import { Container } from './styles'

export const FormColumnLayout = ({
  children,
  columnCount,
  gridGap,
  ...rest
}) => (
  <Container columnCount={columnCount} gridGap={gridGap} {...rest}>
    {children}
  </Container>
)
FormColumnLayout.defaultProps = {
  columnCount: 2,
  gridGap: 8,
}
FormColumnLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  columnCount: PropTypes.number,
  gridGap: PropTypes.number,
}
