/* eslint-disable */

import { loginDuringSignUp } from '@lyfta/components-data/src/Store/Actions/users'
import { getEmail } from '@lyfta/components-data/src/Store/Selectors/persist'
import { I18n } from '@lyfta/components-i18n'
import { ButtonNew, Flex, SignInScreen } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Container, Heading1, Heading2 } from '../../styles'

const SignUpLogin = () => {
  const email = useSelector(getEmail)

  useEffect(() => {
    document.title = 'Registration - Log in'
  }, [])

  const renderCustomButton = ({ handleSubmit }) => {
    return (
      <Container>
        <ButtonNew
          id="btn-login"
          subtype="registration"
          mt={3}
          onClick={handleSubmit}
        >
          <I18n i18nKey="signUp.existingAccount.title" />
        </ButtonNew>
      </Container>
    )
  }
  return (
    <Container>
      <I18n i18nKey="signUp.existingAccount.title" tag={Heading1} />
      <I18n i18nKey="signUp.existingAccount.subTitle" tag={Heading2} />

      <Flex width={1} justifyContent="center" alignItem="center">
        <SignInScreen
          customButton={renderCustomButton}
          withLinks
          paths={paths}
          initialValues={{ email }}
          signInAction={loginDuringSignUp}
        />
      </Flex>
    </Container>
  )
}

export default SignUpLogin
