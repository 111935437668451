import { css, styled, themeGet } from '@lyfta/components-theme'
import { Flex, Text } from '@lyfta/components-ui'

export const StepperContent = styled(Flex).attrs(() => ({
  py: 20,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  width: 100%;
  background-color: ${themeGet('colors.primary')};
  z-index: 2;
  min-height: 100px;
`

export const Step = styled(Flex).attrs(() => ({
  borderRadius: '50%',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  width: [40, 60],
  height: [40, 60],
}))`
  ${({ active }) =>
    !active &&
    css`
      opacity: 0.3;
    `};
`

export const StepIcon = styled(Text).attrs(() => ({
  as: Flex,
  fontSize: [6, 9],
}))`
  // dirty hack
  svg,
  path {
    color: ${themeGet('colors.primary')};
    fill: ${themeGet('colors.primary')};
  }
`

export const StepConnector = styled(Flex).attrs(() => ({
  width: ['12.5%', '15%'],
  maxWidth: 140,
  height: 5,
}))`
  background-color: white;
  opacity: 0.3;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
    `};
`
