import { LIMIT_CHILDREN } from '@lyfta/components-config'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import {
  AnimatedInput,
  ButtonNew,
  Flex,
  FormColumnLayout,
  Icon,
  InputArray,
  Plus,
} from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getProcessing } from '../../../../../../Store/Selectors/signUp'
import { NextIcon } from '../../../styles'
import {
  AddButton,
  ButtonContainer,
  ChildLabel,
  NextButtonResponsive,
  RemoveLabel,
  ShowPasswordButton,
} from '../styles'

// eslint-disable-next-line react/prop-types
const SignUpForm = ({
  handleSubmit,
  form,
  dirty,
  hasValidationErrors,
  hasSubmitErrors,
}) => {
  const processing = useSelector(getProcessing)
  const [passwordFieldType, setPasswordFieldType] = useState('password')
  const togglePasswordVisibility = e => {
    e.stopPropagation()
    setPasswordFieldType(state => (state === 'password' ? 'text' : 'password'))
  }

  const onPasswordInput = e => {
    form.mutators.setValue('passwordConfirmation', e.target.value)
  }

  const handleAddAnotherStudent = fields => {
    fields.push({})
  }

  const handleRemoveStudent = (fields, index) => {
    fields.remove(index)
  }

  // eslint-disable-next-line
  const isValid = hasValidationErrors ? true : !!(hasSubmitErrors && !dirty)

  return (
    <InputArray name="children">
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <>
              <Flex justifyContent="center" mb={4} mt={6}>
                <ChildLabel>
                  {index + 1}
                  {'.'} {}
                  <I18n text="fields.child" />
                </ChildLabel>
                {index > 0 && (
                  <RemoveLabel
                    ml={4}
                    onClick={() => handleRemoveStudent(fields, index)}
                  >
                    {' '}
                    <I18n text="fields.removeChild" />
                  </RemoveLabel>
                )}
              </Flex>

              <FormColumnLayout>
                <AnimatedInput
                  autoFocus
                  description=""
                  label={<I18n text="fields.firstName" />}
                  mb={3}
                  name={`${name}.firstName`}
                />
                <AnimatedInput
                  description=""
                  label={<I18n text="fields.lastNameOptional" />}
                  mb={3}
                  name={`${name}.lastName`}
                />
              </FormColumnLayout>
              <FormColumnLayout>
                <AnimatedInput
                  description=""
                  label={<I18n text="fields.ageOptional" />}
                  mb={3}
                  name={`${name}.age`}
                />
                <AnimatedInput
                  description=""
                  label={<I18n text="fields.nicknameOptional" />}
                  mb={3}
                  name={`${name}.nickname`}
                />
              </FormColumnLayout>

              <FormColumnLayout>
                <AnimatedInput
                  description={getTranslation('tips.username')}
                  label={<I18n text="fields.usernameChild" />}
                  mb={3}
                  name={`${name}.username`}
                  type="text"
                />
                <AnimatedInput
                  autoComplete="off"
                  description={
                    <ShowPasswordButton
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <I18n
                        text={`fields.${
                          passwordFieldType === 'password' ? 'show' : 'hide'
                        }Password`}
                      />
                    </ShowPasswordButton>
                  }
                  label={<I18n text="fields.passwordChild" />}
                  mb={2}
                  name={`${name}.password`}
                  type={passwordFieldType}
                  onChange={onPasswordInput}
                />
              </FormColumnLayout>
            </>
          ))}
          <ButtonContainer
            alignItems="center"
            justifyContent="center"
            width={1}
          >
            {fields.length < LIMIT_CHILDREN && (
              <ButtonNew
                backgroundColor="white"
                borderColor="white"
                fill="primary"
                leadingIcon={
                  <Icon glyph={Plus} height={20} width={20} mr={2} />
                }
                // onClick={handleAddAnotherStudent}
                key="new-child-button"
                title={getTranslation('tooltips.addAnotherStudent')}
                type="button"
                onClick={() => handleAddAnotherStudent(fields)}
              >
                <I18n
                  key="new-child-label"
                  tag={AddButton}
                  text="signUp.childrenAccount.add"
                  underlineOnHover
                />
              </ButtonNew>
            )}
            <NextButtonResponsive
              disabled={processing || isValid}
              marginTop={0}
              ml={7}
              onClick={handleSubmit}
            >
              <I18n
                i18nKey={
                  processing
                    ? 'signUp.processing'
                    : 'signUp.childrenAccount.cta'
                }
              />
              <NextIcon />
            </NextButtonResponsive>
          </ButtonContainer>
        </>
      )}
    </InputArray>
  )
}

SignUpForm.propTypes = {
  dirty: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasSubmitErrors: PropTypes.bool.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
}

export default SignUpForm
