import { styled, themeGet } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'
import { LyftaLogo } from '@lyfta/components-ui/src/Assets/Logo'

export const MainHeader = styled(Flex).attrs(() => ({
  p: 20,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  width: 100%;
  background-color: ${themeGet('colors.primary')};
  z-index: 2;
`

export const LogoContainer = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  flex: 1,
  maxWidth: 675,
}))``

export const Container = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  flex: 1,
}))``

export const Content = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  pt: [4, 9],
  px: [3, 0],
  pb: 3,
}))`
  //align-items: center;
`

export const Header = styled(Flex).attrs(() => ({ mb: 3 }))`
  flex-direction: row;
  justify-content: space-between;
`

export const Logo = styled(LyftaLogo).attrs(() => ({ color: 'black' }))`
  margin-bottom: 16px;
`
