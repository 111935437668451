import { I18n } from '@lyfta/components-i18n'
import range from 'lodash/range'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setOrganizationTeacherCount } from 'Store/Actions/signUp'

import { Container, Heading1, NextButton, NextIcon } from '../../styles'
import { UserCount, UserCountItem } from './styles'

const SetUserCount = () => {
  const [userCount, setUserCount] = useState(null)

  const dispatch = useDispatch()

  const saveCount = () => {
    const resultingCount = userCount === 10 ? 0 : userCount
    dispatch(setOrganizationTeacherCount(resultingCount))
  }

  const changeUserCount = count => () => {
    setUserCount(count)
  }

  return (
    <Container>
      <I18n i18nKey="signUp.userCount.subTitle" tag={Heading1} />

      <UserCount>
        {range(1, 10 + 1).map(count => {
          return (
            <UserCountItem
              active={count === userCount}
              key={count}
              onClick={changeUserCount(count)}
            >
              {count}
              {count === 10 && '+'}
            </UserCountItem>
          )
        })}
      </UserCount>

      <NextButton disabled={userCount == null} onClick={saveCount}>
        <I18n i18nKey="signUp.userType.cta" />
        <NextIcon />
      </NextButton>
    </Container>
  )
}

export default SetUserCount
