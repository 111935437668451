import { styled } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'
import { LyftaLogo } from '@lyfta/components-ui/src/Assets/Logo'

export const Container = styled(Flex).attrs(() => ({
  flexDirection: 'column',
}))`
  height: 100%;
`

export const Content = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  flex: '1 1 auto',
  pt: [4, 7],
  px: [3, 0],
  pb: 3,
}))`
  //align-items: center;
`

export const Header = styled(Flex).attrs(() => ({ mb: 3 }))`
  flex-direction: row;
  justify-content: space-between;
`

export const Logo = styled(LyftaLogo).attrs(() => ({ color: 'black' }))`
  margin-bottom: 16px;
`
