import { BREAKPOINTS, css, styled, themeGet } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Box, ButtonNew, Flex, LyftaLogo, Text } from '@lyfta/components-ui'
import {
  AccordionItem as ReactAccordionItem,
  AccordionItemButton as ReactAccordionItemButton,
} from 'react-accessible-accordion'

import BackgroundImage from '../../../../Assets/banner.jpg'

export const Logo = styled(LyftaLogo)`
  position: absolute;
  left: 24px;
  top: 24px;
  &:hover {
    cursor: pointer;
  }
`

export const LoginButton = styled(ButtonNew)`
  position: absolute;
  right: 24px;
  top: 24px;
  text-transform: none;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 1.5;
`

export const TitleContainer = styled(Flex).attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-top: 8rem;
  }
`

export const FooterTitle = styled(Flex).attrs(() => ({}))`
  font-family: 'Poppins';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.2px;
  justify-content: center;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-top: 5rem;
  }
`
export const FooterGrid = styled(Box).attrs(() => ({
  mt: [2, 9],
}))`
  color: #fff;
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 80%;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const FooterColumn = styled(Flex)`
  flex-direction: column;
  ${props => (props.center ? 'align-items: center;' : '')}
`

export const Container = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
`

export const FormContainer = styled(Flex).attrs(() => ({
  px: [5, 0],
}))`
  width: 100%;
`

export const UnlockDiv = styled(Flex).attrs(() => ({
  py: 4,
}))`
  text-transform: uppercase;
  width: 100%;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 4px;
  justify-content: center;
  color: ${themeGet('colors.neutral_100')};
`

export const InputsContainer = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    flex-direction: column;
  }
`

export const Heading1 = styled(Text).attrs(() => ({
  as: 'h1',
  fontSize: [5, 9],
  lineHeight: [5, 9],
}))`
  text-align: center;
  color: ${themeGet('colors.neutral_100')};

  text-align: center;
  font-family: 'Poppins';
  font-weight: 700;
  line-height: 75px;
  letter-spacing: 2px;
`
export const Heading2 = styled(Heading1).attrs(() => ({
  as: 'h2',
  fontSize: [3, 6],
  lineHeight: [4, 6],
  pt: [2, 3, 4],
  px: [1, 3, 7],
  mt: 3,
  mb: 5,
  maxWidth: ['250px', '100%'],
}))`
  color: ${themeGet('colors.neutral_100')};
  text-align: center;
  font-family: 'Poppins';
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 2px;
`

export const HeroHeader = styled(Flex).attrs(() => ({
  backgroundColor: 'primary',
  // p: [2, 7, 10],
  pb: ['60px', 3, 4],

  height: ['100%', '90vh'],
  maxHeight: ['100%', '900px'],
}))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 900px;
  padding-top: 0vh;
  background-image: url(${BackgroundImage});
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.75)
    ),
    url(${BackgroundImage});
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
`

export const LocaleContainer = styled(Flex).attrs(() => ({}))`
  position: absolute;
  top: 35px;
  right: 200px;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    top: 75px;
    right: 20px;
  }
`

export const Features = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  px: [5, 8, 8, 11],
  py: [5, 8, 8, 9],
  my: [2],
}))`
  min-width: 100vw;
  position: relative;
`

export const Feature = styled(Flex).attrs(() => ({
  flexDirection: ['column', 'row'],
  alignItems: 'center',
  pb: [0, 5],
}))``

export const FeatureContent = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  width: ['100%', '50%'],
}))``

export const FeatureTitle = styled(Text).attrs(() => ({
  fontSize: [6, 7],
  lineHeight: [6, 7],
  color: 'teal_100',
  my: [4],
  textAlign: ['center', 'left'],
}))`
  font-weight: 700;
`

export const FeatureText = styled(Text).attrs(() => ({
  textAlign: ['center', 'left'],
  fontSize: [3, 5],
  lineHeight: [3, 5],
}))``

export const FeatureImage = styled(Flex).attrs(() => ({
  mt: [8, 0],
  maxWidth: ['100%', '50%'],
}))`
  flex-direction: column;
  > img {
    width: 100%;
  }
  ${({ left }) =>
    left &&
    css`
      @media screen and (min-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
        order: -1;
      }
    `}
`

export const Statistics = styled(Flex).attrs(() => ({
  flexWrap: 'wrap',
  alignItems: ['flex-end', 'center'],
  justifyContent: 'space-around',
  px: [0, 10],
  height: ['700px', '340px'],
}))`
  position: relative;
  background: var(
    --Teacher-app-gradients-ta-gradient-a,
    linear-gradient(90deg, #1e2c91 0.42%, #0094ac 99.58%)
  );
  width: 100%;
`
export const NewArrowContainer = styled.a`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: -3em;
  margin-left: -3em;

  background: #d9d6d4;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    width: 4em;
    height: 4em;
    top: -2em;
    margin-left: -2em;
  }
`

export const NewArrowDown = styled.span`
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -1em;
  margin-top: 1.6em;
  width: 2em;
  height: 2em;
  border-top: 0.5em solid #000;
  border-right: 0.5em solid #000;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);

  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-left: -0.7em;
    margin-top: 1em;
    width: 1.5em;
    height: 1.5em;
  }
`

export const StatItem = styled(Flex).attrs(() => ({
  px: 7,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  mb: [7, 0],
  maxWidth: [192, 'none'],
  minHeight: '128px',
  minWidth: '215px',
}))``

export const StatTitle = styled(Text).attrs(() => ({
  // fontSize: [8, 8, 8, 60],
  // lineHeight: [8, 8, 8, 1.2],
  color: 'neutral_100',
}))`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 55.7px;
  line-height: 72px;
  letter-spacing: 0.2px;
  -webkit-text-stroke: 3px white;
  -webkit-text-fill-color: transparent;
`

export const StatText = styled(Text).attrs(() => ({
  fontSize: [3, 4],
  // lineHeight: [3, 4],
  maxWidth: [110, 110, 110, 'none'],
}))`
  color: var(--teacher-app-neutral-neutral-100-tinted-white, #fafafa);
  text-align: center;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
  letter-spacing: 0.2px;
`

export const FAQ = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  px: [5, 8, 8, 11],
  py: [5, 8, 8, 9],
  mb: 9,
  mx: 'auto',
  width: ['100%', '75%'],
}))`
  font-family: 'Poppins';
  .accordion__heading {
    outline: none;
    > div {
      outline: none;
    }
  }
`

export const FAQTitle = styled(Text).attrs(() => ({
  fontSize: [6, 9],
  lineHeight: [6, 9],
  my: 8,
}))`
  font-weight: 700;
  font-family: 'Poppins';
  color: ${themeGet('colors.secondary_130')};
`

export const AccordionItemButton = styled(ReactAccordionItemButton)`
  padding: 23px 27px 23px 22px;
  color: ${themeGet('colors.blue_100')};
  border-radius: 10px;
  border: 3px solid ${themeGet('colors.blue_100')};
  background: var(--Teacher-app-neutral-neutral-200, #f6f5f3);
  box-shadow: ${shadows.skim};

  display: flex;
  align-items: center;
  justify-content: space-between;

  ::after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transition: transform 0.15s ease-in-out;
    transform: rotate(45deg);
  }

  &[aria-expanded='true']::after {
    transform: rotate(-135deg);
  }
`

export const AccordionItem = styled(ReactAccordionItem)`
  /* Elevation/01-skim */
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Contact = styled(Flex).attrs(() => ({
  py: 11,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))`
  width: 100%;
  background-color: ${themeGet('colors.teal_100')};
  color: white;
`

export const ContactTitle = styled(Text).attrs(() => ({
  fontSize: [7, 9],
  lineHeight: [7, 9],
  mb: 7,
}))`
  font-weight: 700;
`

export const CreditText = styled(Text).attrs(() => ({
  fontSize: 3,
  mt: [1, 2],
}))`
  color: ${themeGet('colors.neutral_100')};
  font-family: 'Poppins';
  font-size: 15.1px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
`

export const ContactActions = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: ['column', 'row'],
  px: 4,
}))`
  width: 100%;
`
export const ContactButton = styled(ButtonNew).attrs(() => ({
  p: 3,
}))`
  text-transform: none;
  justify-content: center;
  box-shadow: inset 0 0 0 2px hsl(0deg 0% 100% / 20%);
  width: 100%;
  color: white;
  border-radius: 6px;

  :hover,
  :focus {
    box-shadow: inset 0 0 0 2px white;
  }

  ${({ accent }) =>
    accent &&
    css`
      background-color: black;
      box-shadow: none;
    `};

  @media screen and (min-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    width: 160px;
  }
`

export const Footer = styled(Flex).attrs(() => ({
  py: 8,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))`
  font-family: 'Poppins';
  width: 100%;
  background-color: ${themeGet('colors.black_800')};
  color: white;
`

export const FooterSocials = styled(Flex).attrs(() => ({
  mt: 7,
}))`
  flex-wrap: wrap;
`

export const Link = styled.a`
  color: ${themeGet('colors.neutral_100')};
  margin-right: 30px;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: 300;
  min-height: 30px;
  min-width: 30px;
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-right: 10px;
  }
`
export const FooterColumnTitle = styled(Flex)`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 16px;
`

export const ContactAnchor = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  pointer-events: none;
`
