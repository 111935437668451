import { styled } from '@lyfta/components-theme'
import { Text, css } from '@lyfta/components-ui'

export const Heading1 = styled(Text).attrs(() => ({
  as: 'h1',
  fontSize: [5, 6, 7],
  lineHeight: [5, 6, 7],
  color: 'primary',
}))`
  text-align: center;
`

Heading1.defaultProps = {
  fontWeight: [700],
  mb: [2, 4],
}
export const Heading2 = styled(Heading1).attrs(() => ({
  as: 'h2',
  pt: [2, 3, 4],
  px: [2, 3, 6],
}))`
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `};
`

Heading2.defaultProps = {
  fontSize: [2, 4, 5],
  lineHeight: [2, 4, 5],
}
