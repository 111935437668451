import * as React from 'react'

function SvgSchool(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.507 67.603h6.285V61.32h-6.285v6.283zm0 11.18h6.285v-6.285h-6.285v6.285zm-11.232-11.18h6.284V61.32h-6.284v6.283zm0 11.18h6.284v-6.285h-6.284v6.285zm9.615-48.266H61.196l-10.295-8.362v-7.563h10.467V8H49.727c-.649 0-1.174.526-1.174 1.174v11.011l.004 1.957-10.701 8.375H26.707L13 42.989h15.984l20.542-17.238 20.542 17.238H86.37L73.89 30.517zM49.685 53.086a5.022 5.022 0 110-10.044 5.022 5.022 0 010 10.044zM28.81 67.603h6.284V61.32H28.81v6.283zm0 11.18h6.284v-6.285H28.81v6.285zm-11.232-11.18h6.284V61.32h-6.284v6.283zm0 11.18h6.284v-6.285h-6.284v6.285zm51.067-31.988l-18.012-15.09c-.609-.51-1.606-.51-2.214 0l-18.011 15.09H13v32.537A3.68 3.68 0 0016.668 83h26.278v-7.729a6.74 6.74 0 0113.478 0V83H82.7a3.68 3.68 0 003.669-3.668V46.795H68.645z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSchool
