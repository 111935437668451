import { ButtonNew, css, styled, themeGet } from '@lyfta/components-ui'

export const NextButton = styled(ButtonNew).attrs(() => ({
  width: ['100%', 330],
  borderRadius: '100vw',
  height: 50,
  fontWeight: 700,
  shadow: true,
  type: 'button',
  mt: 5,
}))`
  background-color: ${themeGet('colors.primary')};
  justify-content: center;

  ${({ secondary }) =>
    secondary &&
    css`
      opacity: 0.4;
      background: white;
      color: ${themeGet('colors.primary')};

      &:hover {
        opacity: 1;
        color: white;
        background: ${themeGet('colors.primary')};
      }
    `};
`
