import { getTranslation } from '@lyfta/components-i18n'

export const RESOURCES = [
  {
    label: getTranslation('core.parentResources.community'),
  },
  {
    label: getTranslation('core.parentResources.culture'),
  },
  {
    label: getTranslation('core.parentResources.equality'),
  },
  {
    label: getTranslation('core.parentResources.health'),
  },
  {
    label: getTranslation('core.parentResources.technology'),
  },
  {
    label: getTranslation('core.parentResources.naturalEnvironment'),
  },
  {
    label: getTranslation('core.parentResources.professions'),
  },
  {
    label: getTranslation('core.parentResources.learning'),
  },
  {
    label: getTranslation('core.parentResources.relationship'),
  },
]

export const TIMEZONES = {
  none: '',
  Paris: 'UK/Europe/West Africa (GMT+1)',
  Jerusalem: 'East Africa/Middle East/West Asia (GMT+3)',
  Dhaka: 'Central Asia (GMT+6)',
  Tokyo: 'East Asia (GMT+9)',
  Sydney: 'Australasia (GMT+10)',
  Brasilia: 'South America (GMT-3)',
  'Eastern Time (US & Canada)': 'North America East (GMT-5)',
  'Central Time (US & Canada)': 'North America Central (GMT-6)',
  'Pacific Time (US & Canada)': 'North America West (GMT-8)',
}
