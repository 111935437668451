import { getCurrentLanguage } from '@lyfta/components-i18n'
import Prismic from '@prismicio/client'
import map from 'lodash/map'
import pick from 'lodash/pick'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PrismicClient } from 'Services/Prismic'
import {
  LOAD_PRISMIC,
  loadPrismicFailure,
  loadPrismicSuccess,
} from 'Store/Actions/prismic'

const LANG_MAP = {
  en: 'en-gb',
}

function* loadPrismic({ payload }) {
  const { predicate } = payload

  try {
    const lang = LANG_MAP[getCurrentLanguage()] || getCurrentLanguage()

    const response = yield call(
      PrismicClient.query.bind(PrismicClient),
      Prismic.Predicates.any(predicate.fragment, predicate.values),
      {
        lang,
        orderings: '[document.first_publication_date]',
      },
    )

    if (response) {
      const data = map(response.results, value => pick(value, ['data', 'type']))
      yield put(loadPrismicSuccess(data))
    } else {
      yield loadPrismicSuccess(null)
    }
  } catch (error) {
    yield put(loadPrismicFailure(error))
  }
}

export default function* root() {
  yield all([takeLatest(LOAD_PRISMIC.REQUEST, loadPrismic)])
}
