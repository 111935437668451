import { updateParentUser } from '@lyfta/components-data/src/Store/Actions/users'
import { getViewerId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { ButtonWithTick, Flex } from '@lyfta/components-ui'
import { RESOURCES } from 'Constants/general'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NextButton, NextIcon } from '../../../styles'
import { CountryButtonContainer, CountrySection, SectionHeader } from './styles'

/* eslint-disable max-lines-per-function */
const WelcomeFormFieldset = ({ form, handleSubmit }) => {
  const [submitting, setSubmitting] = useState(false)
  const [resourceSelected, setResourceSelected] = useState([])
  const dispatch = useDispatch()

  const userId = useSelector(getViewerId)

  const formatResources = () => {
    // eslint-disable-next-line
    let finalResources = {}
    RESOURCES.map((item, indexResource) => {
      const elementFound = resourceSelected.find(
        number => number === indexResource + 1,
      )

      if (elementFound) {
        finalResources[item.label] = true
      } else {
        finalResources[item.label] = false
      }
      return true
    })
    return finalResources
  }

  const onSubmit = e => {
    const preferences = formatResources()
    dispatch(updateParentUser()({ id: userId, preferences }))

    handleSubmit(e)
    setSubmitting(form.getState().valid)
  }

  const handleResourcesSelected = resourceId => {
    // eslint-disable-next-line
    let newResourceSelected = [...resourceSelected]
    const elementFound = resourceSelected.find((item, index) => {
      if (resourceId === item) {
        newResourceSelected.splice(index, 1)
        return true
      }
      return undefined
    })

    if (elementFound === undefined) newResourceSelected.push(resourceId)

    setResourceSelected(newResourceSelected)
  }

  return (
    <form
      style={{ alignItem: 'center', width: '100%' }}
      onSubmit={handleSubmit}
    >
      <CountrySection>
        <Flex mb={4}>
          <I18n tag={SectionHeader} text="signUp.parentResources.subTitle" />
        </Flex>
        <CountryButtonContainer>
          {RESOURCES.map((item, index) => {
            const resourceId = index + 1
            const elementFound = resourceSelected.find(i => {
              if (i === resourceId) return true
              return false
            })
            return (
              <ButtonWithTick
                active={elementFound}
                color="primary"
                key={`resource-${resourceId}`}
                label={item.label}
                mr={[0, 4]}
                onClick={() => handleResourcesSelected(resourceId)}
              />
            )
          })}
        </CountryButtonContainer>
      </CountrySection>

      {resourceSelected && (
        <Flex justifyContent="center" width={1}>
          <NextButton disabled={submitting} mt={4} onClick={onSubmit}>
            <I18n i18nKey="signUp.organizationSetup.cta" />
            <NextIcon />
          </NextButton>
        </Flex>
      )}
    </form>
  )
}

WelcomeFormFieldset.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default WelcomeFormFieldset
