import paths from 'Constants/paths'
import SignUpLayout from 'Containers/Layout/SignUp'
import ChildrenAccount from 'Containers/Pages/SignUp/CreateAccount/ChildrenAccount'
import ConfirmSkip from 'Containers/Pages/SignUp/CreateAccount/ConfirmSkip'
import CreateAccount from 'Containers/Pages/SignUp/CreateAccount/CreateAccount'
// import CreateParentAccount from 'Containers/Pages/SignUp/CreateAccount/CreateParentAccount'
import Done from 'Containers/Pages/SignUp/CreateAccount/Done'
import ExistingOrganization from 'Containers/Pages/SignUp/CreateAccount/ExistingOrganization'
import Invitation from 'Containers/Pages/SignUp/CreateAccount/Invitation'
import InvitesSent from 'Containers/Pages/SignUp/CreateAccount/InvitesSent'
import OrganizationSetup from 'Containers/Pages/SignUp/CreateAccount/OrganizationSetup'
import OrganizationType from 'Containers/Pages/SignUp/CreateAccount/OrganizationType'
import ParentResources from 'Containers/Pages/SignUp/CreateAccount/ParentResources'
import ParentSetup from 'Containers/Pages/SignUp/CreateAccount/ParentSetup'
import SelectPlan from 'Containers/Pages/SignUp/CreateAccount/SelectPlan'
import SettingAccount from 'Containers/Pages/SignUp/CreateAccount/SettingAccount'
import SignIn from 'Containers/Pages/SignUp/CreateAccount/SignIn'
import UserCount from 'Containers/Pages/SignUp/CreateAccount/UserCount'
import UserType from 'Containers/Pages/SignUp/CreateAccount/UserType'

export default [
  {
    path: paths.signUp.root,
    component: SignUpLayout,
    routes: [
      {
        path: paths.signUp.createAccount.createUserAccount,
        exact: true,
        component: CreateAccount,
      },
      // {
      //   path: paths.signUp.createAccount.createParentAccount,
      //   exact: true,
      //   component: CreateParentAccount,
      // },
      {
        path: paths.signUp.createAccount.childrenAccount,
        exact: true,
        component: ChildrenAccount,
      },
      {
        path: paths.signUp.createAccount.login,
        exact: true,
        component: SignIn,
      },
      {
        path: paths.signUp.acceptInvite,
        exact: true,
        component: Invitation,
      },
      {
        path: paths.signUp.createAccount.userType,
        exact: true,
        component: UserType,
      },
      {
        path: paths.signUp.createAccount.organizationType,
        exact: true,
        component: OrganizationType,
      },
      {
        path: paths.signUp.createAccount.organization,
        exact: true,
        component: OrganizationSetup,
      },
      {
        path: paths.signUp.createAccount.parentResources,
        exact: true,
        component: ParentResources,
      },
      {
        path: paths.signUp.createAccount.existingOrganization,
        exact: true,
        component: ExistingOrganization,
      },
      {
        path: paths.signUp.createAccount.parentSetup,
        exact: true,
        component: ParentSetup,
      },
      {
        path: paths.signUp.createAccount.plan,
        exact: true,
        component: SelectPlan,
      },
      {
        path: paths.signUp.createAccount.confirmSkip,
        exact: true,
        component: ConfirmSkip,
      },
      {
        path: paths.signUp.createAccount.userCount,
        exact: true,
        component: UserCount,
      },
      {
        path: paths.signUp.createAccount.done,
        exact: true,
        component: Done,
      },
      {
        path: paths.signUp.createAccount.settingAccount,
        exact: true,
        component: SettingAccount,
      },
      {
        path: paths.signUp.createAccount.invitesSent,
        exact: true,
        component: InvitesSent,
      },
    ],
  },
]
