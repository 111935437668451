import {
  loadTeacher,
  requestCreateUser,
} from '@lyfta/components-data/src/Store/Actions/users'
import {
  getEmail,
  getFirstname,
  getLastname,
  getSource,
  getUserType,
} from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  getCurrentTeacher,
  getTeacherUserName,
} from '@lyfta/components-data/src/Store/Selectors/users'
import { I18n } from '@lyfta/components-i18n'
import { Constraints, LyftaForm } from '@lyfta/components-ui'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container, Heading1 } from '../../styles'
import fieldset from './support/fields'

const SetUserType = () => {
  const { search } = useLocation()
  const params = window?.location
    ? new URLSearchParams(search)
    : {
        get() {
          return ''
        },
      }
  const sourceParam = params.get('source')

  const email = useSelector(getEmail)
  const userType = useSelector(getUserType)
  const firstName = useSelector(getFirstname)
  const lastName = useSelector(getLastname)

  const sourceSelector = useSelector(getSource)
  const source = sourceParam || sourceSelector || 'registration_app'
  const generateInitialValues = () => ({
    role: 'teacher',
    email,
    jobRole: userType,
    firstName,
    lastName,
    source,
  })

  useEffect(() => {
    document.title = 'Registration - Create your login'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.setPassword.title" tag={Heading1} />

      <LyftaForm
        constraints={{
          ...Constraints.firstNameConstraint,
          ...Constraints.lastNameConstraint,
          ...Constraints.emailOrganizationUniqueConstraint(),
          ...Constraints.passwordConstraint,
          ...Constraints.passwordConfirmationConstraint,
          ...Constraints.privacyPolicyConstraint,
        }}
        createAction={requestCreateUser}
        createInitialValues={generateInitialValues}
        fields={fieldset}
        loadAction={loadTeacher}
        modelName="teachers"
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        noWrap
        recordNameSelector={getTeacherUserName}
        recordSelector={getCurrentTeacher}
        withoutHeader
        wrapperProps={{ flex: 20, flexDirection: 'column', width: '100%' }}
      />
    </Container>
  )
}

export default SetUserType
