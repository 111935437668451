import * as React from 'react'

function SvgAdd2(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#FFF" fillRule="nonzero">
        <path d="M19.274 40.3h61.453a2 2 0 012 2v14.555a2 2 0 01-2 2H19.274a2 2 0 01-2-2V42.3a2 2 0 012-2z" />
        <path d="M59.277 18.85v61.454a2 2 0 01-2 2H42.722a2 2 0 01-2-2V18.851a2 2 0 012-2h14.555a2 2 0 012 2z" />
      </g>
    </svg>
  )
}

export default SvgAdd2
