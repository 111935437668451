import PropTypes from 'prop-types'
import React from 'react'

import NoWebinar from './NoWebinar'
import Webinar from './Webinar'

const Plans = ({ webinarPlan, setWebinarPlan }) => {
  return webinarPlan ? (
    <Webinar onSetWebinarPlan={setWebinarPlan} />
  ) : (
    <NoWebinar onSetWebinarPlan={setWebinarPlan} />
  )
}

Plans.propTypes = {
  setWebinarPlan: PropTypes.object.isRequired,
  webinarPlan: PropTypes.bool.isRequired,
}

export default Plans
