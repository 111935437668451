import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { I18n } from '@lyfta/components-i18n'
import { Email2, Flex, Text } from '@lyfta/components-ui'
import { openInTeacherApp } from '@lyfta/components-ui/src/Services/Utils'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Container, Heading1, NextButton } from '../../styles'

function InvitesSent() {
  const jwt = useSelector(getJWT)

  useEffect(() => {
    document.title = 'Registration - Done and invites sent'
  }, [])

  return (
    <Container>
      <Flex justifyContent="center" mb={[5, 7]}>
        <I18n i18nKey="signUp.done.title" mb={0} tag={Heading1} />
      </Flex>
      <Text fontSize="10rem" textAlign="center">
        <Email2 />
      </Text>
      <Flex justifyContent="center" my={5} width={200}>
        <I18n
          i18nKey="signUp.done.subtitle"
          lineHeight={3}
          tag={Text}
          textAlign="center"
        />
      </Flex>
      <Flex justifyContent="center" mt={2}>
        <NextButton mt={0} onClick={openInTeacherApp({ jwt })}>
          <I18n i18nKey="signUp.done.cta" />
        </NextButton>
      </Flex>
    </Container>
  )
}

export default InvitesSent
