import { AuthLayout, ChangePasswordScreen } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React from 'react'

const ChangePassword = () => {
  return (
    <AuthLayout>
      <ChangePasswordScreen paths={paths} withHeader withLinks />
    </AuthLayout>
  )
}

export default ChangePassword
