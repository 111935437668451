import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { Text } from '@lyfta/components-ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmSkip, selectPlan } from 'Store/Actions/signUp'

import { Heading1 } from '../../../../../Components/Headings'
import { NextButton } from '../../../../../Components/NextButton'
import { Container, NextIcon } from '../../styles'
import Plans from './Plans'
import { Actions } from './styles'

const SelectPlan = () => {
  const dispatch = useDispatch()

  const viewer = useSelector(getViewer)
  const { organization } = viewer

  const isCCGL = organization?.licensings?.some(
    ({ license }) => license?.licenseProgram === 'CCGL',
  )
  const [webinarPlan, setWebinarPlan] = useState(true)

  const redirectToWebinar = () => dispatch(selectPlan())
  const redirectConfirmSkip = () => dispatch(confirmSkip())

  return (
    <Container>
      {!organization ? (
        <I18n i18nKey="actions.loading" tag={Text} />
      ) : (
        <>
          <I18n
            i18nKey="signUp.plan.secondTitle"
            marginBottom={5}
            tag={Heading1}
            width="70%"
          />
          <Plans
            isCCGL={isCCGL}
            redirectToWebinar
            setWebinarPlan={setWebinarPlan}
            webinarPlan={webinarPlan}
          />

          <Actions>
            <NextButton
              fontSize={16}
              mt={0}
              onClick={webinarPlan ? redirectToWebinar : redirectConfirmSkip}
            >
              <I18n i18nKey="signUp.plan.actions.continue" />
              <NextIcon />
            </NextButton>
          </Actions>
        </>
      )}
    </Container>
  )
}

export default SelectPlan
