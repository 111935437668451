import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import {
  BREAKPOINTS,
  ButtonNew,
  Flex,
  HeartLight,
  css,
  styled,
  themeGet,
} from '@lyfta/components-ui'
import React from 'react'

export const NextButton = styled(ButtonNew).attrs(() => ({
  width: ['100%', 330],
  borderRadius: '100vw',
  height: 50,
  fontWeight: 700,
  shadow: true,
  type: 'button',
}))`
  background-color: ${themeGet('colors.primary')};
  justify-content: center;

  ${({ secondary }) =>
    secondary &&
    css`
      opacity: 0.7;
    `};
`

NextButton.defaultProps = {
  fontSize: [3, 5],
}

export const HeartIcon = styled(Flex).attrs(() => ({
  children: <HeartLight height="32px" width="32px" />,
  minWidth: 32,
  minHeight: 32,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  background-color: ${themeGet('colors.primary')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`

export const Container = styled(Flex).attrs(() => ({
  alignSelf: ['unset', 'center'],
}))`
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  height: 100%;
  width: 60%;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    width: 100%;
    margin-top: 1rem;
  }
`

export const Wrapper = styled(Flex).attrs(() => ({
  flexDirection: ['column', 'row'],
  marginTop: '2rem',
}))`
  width: 100%;
  justify-content: center;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-top: 1rem;
  }
  @media screen and (min-width: ${breakpoints[BREAKPOINTS.DESKTOP]}) {
    width: 70%;
  }
`
