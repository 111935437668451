import { createReducer } from '@lyfta/components-data'
import isEmpty from 'lodash/isEmpty'

import { LOAD_PRISMIC } from '../Actions/prismic'

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: null,
  error: null,
}

const handlers = {
  [LOAD_PRISMIC.REQUEST]: state => {
    const emptyData = isEmpty(state.data)

    // Respect persisted state
    return {
      ...state,
      isLoading: emptyData,
      isLoaded: !emptyData,
    }
  },

  [LOAD_PRISMIC.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      data: payload.data,
      isLoading: false,
      isLoaded: true,
    }
  },

  [LOAD_PRISMIC.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false,
      isLoaded: false,
    }
  },
}

export default createReducer(initialState, handlers)
