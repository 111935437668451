import * as React from 'react'

function SvgClose2(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000" fillRule="nonzero">
        <path d="M11.23 70.662L70.73 11.16a2 2 0 012.829 0L88.4 26a2 2 0 010 2.829L28.9 88.33a2 2 0 01-2.828 0l-14.842-14.84a2 2 0 010-2.83z" />
        <path d="M28.899 11.16l59.502 59.502a2 2 0 010 2.828L73.56 88.331a2 2 0 01-2.829 0L11.23 28.83a2 2 0 010-2.829L26.071 11.16a2 2 0 012.828 0z" />
      </g>
    </svg>
  )
}

export default SvgClose2
