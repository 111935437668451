import { I18n } from '@lyfta/components-i18n'
import { Check2, Close2, Flex, Text } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import {
  ButtonSelected,
  FeatureText,
  IconContainer,
  PlanCard,
  PlanCards,
  PlanFeature,
  PlanFeatures,
} from './styles'

/* eslint-disable max-lines-per-function */
function WebinarPlan({ onSetWebinarPlan }) {
  return (
    <PlanCards>
      <PlanFeatures>
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          marginBottom={1}
          marginTop="16px"
          paddingLeft="1rem"
        >
          <I18n i18nKey="signUp.plan.features.freeFor" tag={FeatureText} />
        </PlanFeature>
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          marginBottom={1}
          paddingLeft="1rem"
        >
          <I18n
            i18nKey="signUp.plan.features.numberStoryworlds"
            tag={FeatureText}
          />
        </PlanFeature>
        <PlanFeature
          borderBottom="solid"
          borderLeft="solid"
          borderTop="solid"
          paddingLeft="1rem"
        >
          <I18n i18nKey="signUp.plan.features.webinar" tag={FeatureText} />
        </PlanFeature>
      </PlanFeatures>

      <PlanCard color="primary" dark height="268px">
        <I18n
          i18nKey="signUp.plan.webinar.title"
          style={{
            letterSpacing: '3.75px',
            marginBottom: '5px',
            fontWeight: 700,
          }}
          tag={FeatureText}
        />
        <Flex flexDirection="column" width={1}>
          <PlanFeature>
            <I18n
              i18nKey="signUp.plan.webinar.freeFor"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>
          <PlanFeature>
            <I18n
              i18nKey="signUp.plan.webinar.numberStoryworlds"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>
          <PlanFeature>
            <IconContainer dark>
              <Check2
                color="white"
                fontSize="1.2rem"
                textAlign="center"
                width="100%"
              />
            </IconContainer>
          </PlanFeature>
        </Flex>
        <ButtonSelected>
          <I18n i18nKey="signUp.plan.buttonSelected" tag={Text} />
        </ButtonSelected>
      </PlanCard>
      <PlanCard
        color="primary"
        height="275px"
        mb={[4, 0]}
        p={0}
        py={4}
        transparent
        onClick={() => {
          onSetWebinarPlan(false)
        }}
      >
        <I18n
          i18nKey="signUp.plan.noWebinar.title"
          style={{
            letterSpacing: '3.75px',
            marginBottom: '5px',
            fontWeight: 700,
          }}
          tag={FeatureText}
        />
        <Flex flexDirection="column" mb={0} pb={0} width={1}>
          <PlanFeature
            borderBottom="solid"
            borderRight="solid"
            borderTop="solid"
            marginBottom={1}
          >
            <I18n
              i18nKey="signUp.plan.noWebinar.freeFor"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>

          <PlanFeature
            borderBottom="solid"
            borderRight="solid"
            borderTop="solid"
            marginBottom={1}
          >
            <I18n
              i18nKey="signUp.plan.noWebinar.numberStoryworlds"
              tag={FeatureText}
              textAlign="center"
              width="100%"
            />
          </PlanFeature>

          <PlanFeature
            borderBottom="solid"
            borderRight="solid"
            borderTop="solid"
          >
            <IconContainer transparent>
              <Close2 fontSize="1rem" textAlign="center" width="100%" />
            </IconContainer>
          </PlanFeature>
        </Flex>
      </PlanCard>
    </PlanCards>
  )
}

WebinarPlan.propTypes = {
  onSetWebinarPlan: PropTypes.object.isRequired,
}

export default WebinarPlan
