import { AUTHENTICATE } from '@lyfta/components-data/src/Store/Actions/auth'
import {
  LOAD_VIEWER,
  loadViewer,
  logOut,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getIsDataLoaded } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { PRISMIC_TYPES } from 'Constants/prismic'
import { put, race, select, take } from 'redux-saga/effects'
import { COMPLETE_REHYDRATION, completeRefetch } from 'Store/Actions/app'
import { requestLoadPrismic } from 'Store/Actions/prismic'

export default function* restoreSession() {
  while (true) {
    yield take([COMPLETE_REHYDRATION, AUTHENTICATE.SUCCESS])

    const isDataLoaded = yield select(getIsDataLoaded)
    const jwt = yield select(getJWT)

    yield put(
      requestLoadPrismic({
        fragment: 'document.type',
        values: [PRISMIC_TYPES.FAQ, PRISMIC_TYPES.REGISTRATION_HOME_PAGE],
      }),
    )

    if (jwt) {
      if (isDataLoaded) {
        yield put(completeRefetch())
      } else {
        yield put(loadViewer())

        const { success } = yield race({
          success: take(LOAD_VIEWER.SUCCESS),
          failure: take(LOAD_VIEWER.FAILURE),
        })

        if (success) {
          yield put(completeRefetch())
        } else {
          yield put(logOut())
        }
      }
    }
  }
}
