import { Flex, css, styled, themeGet } from '@lyfta/components-ui'

export const IconContainer = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))`
  ${({ dark }) =>
    dark &&
    css`
      svg,
      path {
        fill: ${themeGet('colors.white')};
      }
    `};
  ${({ transparent }) =>
    transparent &&
    css`
      svg,
      path {
        fill: ${themeGet('colors.primary')};
      }
    `};
`
