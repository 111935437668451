import { all, fork } from 'redux-saga/effects'

import prismic from './prismic'
import restoreSession from './restoreSession'
import signUp from './signUp'
import users from './users'
import viewer from './viewer'

const rootSaga = function* root() {
  yield all([
    fork(restoreSession),
    fork(viewer),
    fork(users),
    fork(signUp),
    fork(prismic),
  ])
}

export default rootSaga
