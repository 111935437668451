import { AuthLayout, ResetPasswordScreen } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React, { useEffect } from 'react'

const ForgotPassword = () => {
  useEffect(() => {
    document.title = 'Registration - Forgot password'
  }, [])

  return (
    <AuthLayout>
      <ResetPasswordScreen paths={paths} withHeader withLinks />
    </AuthLayout>
  )
}

export default ForgotPassword
