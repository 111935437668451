import { combinedEmailOrganizationConstraint } from '@lyfta/components-ui/src/Constants/constraints'
import { get, trim } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import validate from 'validate.js'

import welcomeFormFields from './form'
import { Container } from './styles'

const handleEmailEntry = (args, state, utils) => {
  const email = get(state, 'formState.values.email', '')
  utils.changeValue(state, 'email', () => trim(email.toLowerCase()))
}

const WelcomeForm = ({ onSubmit, monochrome, source, id, email }) => {
  const initialValues = {
    email,
  }
  const validateForm = values =>
    validate(values, {
      ...combinedEmailOrganizationConstraint(id),
    })

  return (
    <Container>
      <Form
        id={id}
        initialValues={initialValues}
        monochrome={monochrome}
        mutators={{ handleEmailEntry }}
        render={welcomeFormFields}
        source={source}
        validate={validateForm}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
WelcomeForm.defaultProps = {
  monochrome: false,
  id: '',
  email: '',
  source: 'registration_app',
}
WelcomeForm.propTypes = {
  email: PropTypes.string,
  id: PropTypes.string,
  monochrome: PropTypes.bool,
  source: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}
export default WelcomeForm
