import Public from 'Containers/Layout/Public'
import Welcome from 'Containers/Pages/SignUp/Welcome'

import signIn from './sign-in'
import signUp from './sign-up'
import signUpPublic from './sign-up/public'
import webinarBooking, { publicWebinarRegistration } from './webinarBooking'

const privateRoutes = [
  ...webinarBooking,
  ...signUp,
  ...signIn,
  {
    component: Welcome,
  },
]

const publicRoutes = [...publicWebinarRegistration, ...signIn, ...signUpPublic]

export default {
  build: authenticated => [
    authenticated
      ? {
          component: Public,
          routes: privateRoutes,
        }
      : { component: Public, routes: publicRoutes },
  ],
}
