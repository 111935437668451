import * as React from 'react'

function SvgLyftaLogoSymbolBorder(props) {
  return (
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="40" cy="40" fill="white" r="40" />
      <path
        clipRule="evenodd"
        d="M44.3352 20.4842V36.0667H53.5152V32.8463H47.6313L47.6334 20.4842H44.3352Z"
        fill="#0094AC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M47.6334 20.4842H44.3352V36.0667H53.5152V32.8461H47.6313L47.6334 20.4842Z"
        fillRule="evenodd"
        stroke="#0094AC"
      />
      <path
        clipRule="evenodd"
        d="M26.3744 44.678V59.4404H35.5544V56.2596H29.6705V44.678H26.3744Z"
        fill="#0094AC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M26.3744 44.678V59.4404H35.5544V56.2598H29.6705V44.678H26.3744Z"
        fillRule="evenodd"
        stroke="#0094AC"
      />
      <path
        clipRule="evenodd"
        d="M50.2192 44.678V56.2596H44.3352V59.4404H53.5152V44.678H50.2192Z"
        fill="#0094AC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M50.2192 44.678H53.5152V59.4404H44.3352V56.2598H50.2192V44.678Z"
        fillRule="evenodd"
        stroke="#0094AC"
      />
      <path
        clipRule="evenodd"
        d="M26.3744 20.4842V35.2465H29.6697V23.665H35.5544V20.4842H26.3744Z"
        fill="#0094AC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M29.6697 35.2465H26.3744V20.4842H35.5544V23.6652H29.6697V35.2465Z"
        fillRule="evenodd"
        stroke="#0094AC"
      />
    </svg>
  )
}

export default SvgLyftaLogoSymbolBorder
