import { BREAKPOINTS, styled } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { Flex, Text } from '@lyfta/components-ui'

import { NextButton } from '../../../styles'

export const AddButton = styled(Text).attrs(() => ({
  fontSize: [5, 5, 5],
  lineHeight: [5, 5, 5],
  color: 'primary',
}))`
  text-align: center;
  &:focus,
  &:hover {
    ${p => (p.underlineOnHover ? 'text-decoration: underline' : '')}
  }
`

export const ChildLabel = styled(Text).attrs(() => ({
  fontSize: [7, 7, 7],
  lineHeight: [7, 7, 7],
  color: 'primary',
}))`
  text-align: center;
`
export const RemoveLabel = styled(Text).attrs(() => ({
  fontSize: 3,
  color: 'red_100',
}))`
  align-self: center;
  cursor: pointer;
  margin-left: 1rem;
  &:hover {
    text-decoration: underline;
  }
`
export const ShowPasswordButton = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  appearance: none;
`

export const ButtonContainer = styled(Flex)`
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    flex-direction: column;
  }
`

export const NextButtonResponsive = styled(NextButton)`
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    margin-left: 0;
  }
`
