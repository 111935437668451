import { createReducer } from '@lyfta/components-data/src/helpers'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  SET_ADDRESS,
  SET_EMAIL,
  SET_PROCESSING,
  SET_TYPE,
} from '../Actions/signUp'

const initialState = {
  email: null,
  firstName: null,
  lastName: null,
  signUpType: null,
  inviteToken: null,
  address: {},
  processing: false,
}

const handlers = {
  [SET_EMAIL]: (state, { email, firstName, lastName, source }) =>
    state.merge({ email, firstName, lastName, source }),
  [SET_TYPE]: (state, { signUpType }) => state.merge({ signUpType }),
  [SET_ADDRESS]: (state, { address }) => state.merge({ address }),
  [SET_PROCESSING]: (state, { processing }) => state.merge({ processing }),
  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
