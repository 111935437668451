import { BREAKPOINTS } from '@lyfta/components-theme'
import { css, styled } from '@lyfta/components-theme/src/styling/components'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { ButtonNew, Check2, Flex, Text, themeGet } from '@lyfta/components-ui'
import React from 'react'

export const Container = styled(Flex)`
  flex-direction: column;

  width: 100%;
  margin-top: 24px;

  > form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      max-width: 250px;
    }

    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    button {
      margin-bottom: 16px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
`
export const CountrySection = styled(Flex).attrs(() => ({}))`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    align-items: center;
  }
`
export const CountryButtonContainer = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  width: 100%;
  @media only screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    align-items: center;
    flex-direction: column;
  }
`

export const SectionHeader = styled(Text).attrs(() => ({
  level: 3,
  mt: 3,
  mb: 3,
  ml: 0,
  mr: 0,
}))`
  color: ${themeGet('colors.primary')};
  line-height: 20px;
  font-size: 20px;
`

export const LocationButton = styled(ButtonNew).attrs(({ inverse }) => ({
  type: 'button',
  mb: 3,
  width: ['100%', 260],
  height: 50,
  shadow: !inverse,
}))`
  border-radius: 100vw;
  border: 2px solid ${themeGet('colors.primary')};
  transition: all 0.17s;
  justify-content: center;
  font-weight: 600;

  > div {
    background-color: ${themeGet('colors.primary')};

    svg,
    path {
      fill: ${themeGet('colors.primary')};
    }
  }

  &:hover:enabled {
    background-color: ${themeGet('colors.lyftaTurquoiseLight')} !important;
    border: 2px solid ${themeGet('colors.lyftaTurquoiseLight')};
  }

  &:hover {
    > div {
      background-color: ${themeGet('colors.white')};

      svg,
      path {
        fill: ${themeGet('colors.lyftaTurquoiseLight')};
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.teal_100')};
      color: ${themeGet('colors.white')};
      > div {
        background-color: ${themeGet('colors.white')};

        svg,
        path {
          fill: ${themeGet('colors.teal_100')};
        }
      }
    `};
`

export const LocationCheckedIcon = styled(Flex).attrs(() => ({
  children: <Check2 />,
  width: 20,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  transition: all 0.17s;

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.white')};
    `};
`
