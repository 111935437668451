import { each } from 'lodash'

export const translateGeocoderResultToStructured = results => {
  const { address_components: components } = results

  if (!components) {
    return {}
  }

  let city = null
  let postalCode = null
  const searchType = components[0].types[0]

  each(components, component => {
    if (component.types.includes('postal_code')) {
      postalCode = component.long_name
    }
    if (
      component.types.includes('postal_town') ||
      component.types.includes('locality')
    ) {
      city = component.long_name
    }
    if (
      component.types.includes('administrative_area_level_2') &&
      searchType === 'administrative_area_level_2'
    ) {
      city = component.long_name
    }
    if (postalCode === null) postalCode = '%'
  })

  return { city, postalCode }
}
