import { I18n } from '@lyfta/components-i18n'
import { Add2, AnimatedInput, Flex } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import { FAB } from './styles'

const InviteFormFieldset = ({ form, invalid, handleSubmit }) => {
  const doOnSubmit = (...args) => {
    handleSubmit(...args)
    form.restart()
  }

  return (
    <form onSubmit={doOnSubmit}>
      <Flex flexDirection="column">
        <Flex>
          <AnimatedInput
            label={<I18n text="fields.email" />}
            name="email"
            type="email"
            onChange={form.mutators.handleEmailEntry}
          />
          <FAB disabled={invalid} ml={3} type="submit">
            <Add2 />
          </FAB>
        </Flex>
      </Flex>
    </form>
  )
}

InviteFormFieldset.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
}

export default InviteFormFieldset
