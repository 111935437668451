import { appEnv } from '@lyfta/components-config'
import {
  setEmail as setEmailViewer,
  setFirstname,
  setLastname,
  setRedirectFinalStep,
  setSource,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getIsAuthenticated } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import { Flex, LogoutButton, LyftaLogo, Text } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import { RichText } from 'prismic-reactjs'
import React, { useCallback, useEffect } from 'react'
import {
  Accordion,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getHomePageStatistics,
  getPrismicFAQ,
  getPrismicLoaded,
  getPrismicLoading,
  getPrismicRegistrationHomePage,
} from 'Store/Selectors/prismic'

import WelcomeForm from './Form'
import {
  AccordionItem,
  AccordionItemButton,
  ContactAnchor,
  Container,
  CreditText,
  FAQ,
  FAQTitle,
  Footer,
  FooterColumn,
  FooterColumnTitle,
  FooterGrid,
  FooterSocials,
  FooterTitle,
  FormContainer,
  Heading1,
  Heading2,
  HeroHeader,
  Link,
  LoginButton,
  Logo,
  NewArrowContainer,
  NewArrowDown,
  StatItem,
  StatText,
  StatTitle,
  Statistics,
  TitleContainer,
} from './styles'

// import { escape } from 'lodash'

const Welcome = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { search } = useLocation()
  const params = window?.location
    ? new URLSearchParams(search)
    : {
        get() {
          return ''
        },
      }
  const source = params.get('source')
  const redirectFinalStep = params.get('redirect')
  const emailURL = params.get('email')
  const firstnameURL = params.get('first_name')
  const lastnameURL = params.get('last_name')
  const isAuthenticated = useSelector(getIsAuthenticated)

  const prismicLoading = useSelector(getPrismicLoading)
  const prismicLoaded = useSelector(getPrismicLoaded)
  const prismicHomePage = useSelector(getPrismicRegistrationHomePage)
  const prismicFAQ = useSelector(getPrismicFAQ)
  // const features = useSelector(getHomePageFeatures)
  const statistics = useSelector(getHomePageStatistics)

  const redirectUser = emailURL && firstnameURL && lastnameURL

  const proceedToSignUp = useCallback(({ email }) => {
    dispatch(setEmailViewer(email))
    dispatch(setRedirectFinalStep(redirectFinalStep))
    dispatch(setFirstname(firstnameURL))
    dispatch(setLastname(lastnameURL))
    dispatch(setSource(source))
    // Twitter conversion tracking event code
    /* eslint-disable */
    if (appEnv === 'production' && twq) {
      twq('event', 'tw-o5b1e-of5x7', {})
    }
  }, [])
  /* eslint-enable */
  useEffect(() => {
    if (emailURL) {
      dispatch(setEmailViewer(emailURL))
      dispatch(setRedirectFinalStep(redirectFinalStep))
      dispatch(setFirstname(firstnameURL))
      dispatch(setLastname(lastnameURL))
      dispatch(setSource(source))
    }
  }, [])

  const handleLogoClick = () => {
    window.open('https://www.lyfta.com/')
  }

  const onLoginClick = useCallback(
    () => history.push(paths.signUp.createAccount.login),
    [],
  )
  const SVS_B = (eAmt, position) => {
    if (position === 'center' || position === '') window.scrollBy(0, eAmt / 2)
    if (position === 'top') window.scrollBy(0, eAmt)
  }

  const supportsSmoothScrolling = () => {
    const { body } = document
    const scrollSave = body.style.scrollBehavior
    body.style.scrollBehavior = 'smooth'
    const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth'
    body.style.scrollBehavior = scrollSave
    return hasSmooth
  }

  const SmoothVerticalScrolling = (element, time, position) => {
    const eTop = element.getBoundingClientRect().top
    const eAmt = eTop / 100
    let curTime = 0
    while (curTime <= time) {
      window.setTimeout(SVS_B, curTime, eAmt, position)
      curTime += time / 100
    }
  }

  const scrollDown = event => {
    if (supportsSmoothScrolling()) {
      // Scroll to a certain element
      document.querySelector('#feature').scrollIntoView({
        behavior: 'smooth',
      })
      return
    }
    event.preventDefault()
    const scrollToElem = document.getElementById('feature')
    SmoothVerticalScrolling(scrollToElem, 300, 'top')
  }

  useEffect(() => {
    document.title = 'Registration - Welcome'
  }, [])

  if (!redirectUser)
    return (
      <Container>
        {appEnv === 'production' && (
          <Helmet>
            <script>
              {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '353903519157081');
  fbq('track', 'PageView');`}
            </script>
            <script>
              {/* Twitter conversion tracking base code */}
              {`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','o5b1e');`}
            </script>
          </Helmet>
        )}
        <HeroHeader>
          <ContactAnchor id="contact-form" />
          <Logo onClick={handleLogoClick} />
          {/* <LocaleContainer> */}
          {/*  <Locale /> */}
          {/* </LocaleContainer> */}
          {isAuthenticated ? (
            <LogoutButton>
              {performLogout => (
                <LoginButton
                  title={getTranslation('actions.logout')}
                  onClick={performLogout}
                >
                  <I18n i18nKey="actions.logout" />
                </LoginButton>
              )}
            </LogoutButton>
          ) : (
            <LoginButton id="btn-login" onClick={onLoginClick}>
              <I18n i18nKey="auth.login" />
            </LoginButton>
          )}

          {prismicLoading && !prismicLoaded ? (
            <Text color="white">
              <I18n i18nKey="actions.loading" />
            </Text>
          ) : (
            <>
              {RichText.asText(prismicHomePage?.title) && (
                <TitleContainer>
                  <Heading1>
                    {RichText.asText(prismicHomePage?.title)}{' '}
                  </Heading1>
                  <Heading2>
                    {RichText.asText(prismicHomePage?.sub_head)}{' '}
                  </Heading2>
                </TitleContainer>
              )}
              <FormContainer>
                <WelcomeForm
                  email={emailURL || ''}
                  id="form-submit-general-proceed-sign-up"
                  source={source}
                  onSubmit={proceedToSignUp}
                />
              </FormContainer>
              <CreditText>
                <I18n i18nKey="welcome.noCredit" />
              </CreditText>
            </>
          )}
        </HeroHeader>

        {/* <Features id="feature">
          <NewArrowContainer onClick={scrollDown}>
            <NewArrowDown />
          </NewArrowContainer> */}
        {/* {features?.map((feature, index) => {
            const left = index % 2 === 1

            const { image_to_display: image } = feature?.primary
            return (
              <Feature key={feature.id}>
                <FeatureContent ml={!left && [0, 7]} mr={left && [0, 7]}>
                  <FeatureTitle>
                    {RichText && RichText.asText(feature?.primary?.title1)}
                  </FeatureTitle>
                  <FeatureText>
                    {RichText && RichText.asText(feature?.primary?.body1)}
                  </FeatureText>
                </FeatureContent>
                <FeatureImage left={!left}>
                  <img
                    alt={
                      image?.alt || RichText.asText(feature?.primary?.title1)
                    }
                    src={image?.url}
                  />
                </FeatureImage>
              </Feature>
            )
          })} */}
        {/* </Features> */}
        <Statistics>
          <NewArrowContainer onClick={scrollDown}>
            <NewArrowDown />
          </NewArrowContainer>
          {statistics?.items?.map(item => {
            return (
              <StatItem key={item.id}>
                <StatTitle>
                  {RichText && RichText.asText(item?.statistic)}
                </StatTitle>
                <StatText>
                  {RichText && RichText.asText(item?.statistic_title)}
                </StatText>
              </StatItem>
            )
          })}
        </Statistics>

        <FAQ>
          <FAQTitle>{RichText.asText(prismicHomePage?.faq_title)}</FAQTitle>
          <Accordion allowMultipleExpanded allowZeroExpanded>
            {prismicFAQ?.map(faqItem => {
              return (
                <AccordionItem key={faqItem.id}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Text fontSize={[3, 4]}>
                        {RichText.asText(faqItem?.title)}
                      </Text>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <Flex my={3}>
                    <AccordionItemPanel>
                      <Flex my={3}>{RichText.asText(faqItem?.body)}</Flex>
                    </AccordionItemPanel>
                  </Flex>
                </AccordionItem>
              )
            })}
          </Accordion>
        </FAQ>

        <Footer>
          <FormContainer flexDirection="column">
            <FooterTitle>
              <I18n i18nKey="welcome.footerTitle" />
            </FooterTitle>
            <WelcomeForm
              id="input-teacher-proceed-sign-up"
              monochrome
              source={source}
              onSubmit={proceedToSignUp}
            />
          </FormContainer>
          <CreditText>
            <I18n i18nKey="welcome.noCredit" />
          </CreditText>
          <FooterGrid>
            <FooterColumn mt={2}>
              <LyftaLogo
                style={{ cursor: 'pointer' }}
                onClick={handleLogoClick}
              />
              <FooterSocials>
                <Link href="https://twitter.com/LyftaEd" target="_blank">
                  <img
                    height="25px"
                    width="25px"
                    alt="x"
                    src="https://assets-global.website-files.com/63c02df3c8e1fc4d9bed5936/65fabbdb446dd39355db32eb_x-icon.svg"
                  />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/lyftaed/"
                  target="_blank"
                >
                  <img
                    height="30px"
                    src="https://assets-global.website-files.com/63c02df3c8e1fc4d9bed5936/64dd1ba7ad486a02211133f2_linkedin.svg"
                    loading="lazy"
                    alt="linkeding"
                  />
                </Link>
                <Link href="https://www.facebook.com/lyftaed/" target="_blank">
                  <img
                    height="30px"
                    src="https://assets-global.website-files.com/63c02df3c8e1fc4d9bed5936/64dd1ba7ad486a02211133f5_Facebook.svg"
                    loading="lazy"
                    alt=""
                  />
                </Link>
                <Link
                  href="https://www.instagram.com/lyftaworld/"
                  target="_blank"
                >
                  <img
                    height="30px"
                    src="https://assets-global.website-files.com/63c02df3c8e1fc4d9bed5936/63c02df3c8e1fc1c84ed5a9a_insta.svg"
                    loading="lazy"
                    alt=""
                  />
                </Link>
              </FooterSocials>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnTitle>
                {' '}
                <I18n i18nKey="welcome.footer.information" />
              </FooterColumnTitle>
              <Link
                href="https://www.lyfta.com/resources/case-studies/"
                target="_blank"
              >
                <I18n i18nKey="welcome.footer.schoolImpact" />
              </Link>
              <Link href="https://www.lyfta.com/focus-areas" target="_blank">
                <I18n i18nKey="welcome.footer.lyftaPedagogy" />
              </Link>
              <Link href="https://www.lyfta.com/pricing" target="_blank">
                <I18n i18nKey="welcome.footer.pricing" />
              </Link>
              <Link href="https://www.lyfta.com/contact" target="_blank">
                <I18n i18nKey="welcome.footer.contact" />
              </Link>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnTitle>
                <I18n i18nKey="welcome.footer.community" />
              </FooterColumnTitle>

              <Link href="https://www.lyfta.com/blog" target="_blank">
                <I18n i18nKey="welcome.footer.blog" />
              </Link>
              <Link
                href="https://www.lyfta.com/content-calendar"
                target="_blank"
              >
                <I18n i18nKey="welcome.footer.lyftaCalendar" />
              </Link>
              <Link
                href="https://impact.lyfta.com/live-lessons"
                target="_blank"
              >
                <I18n i18nKey="welcome.footer.liveLessons" />
              </Link>
              <Link href="https://www.lyfta.com/resources/cpd" target="_blank">
                <I18n i18nKey="welcome.footer.professionalDev" />
              </Link>
            </FooterColumn>
            <FooterColumn>
              <FooterColumnTitle>
                <I18n i18nKey="welcome.footer.company" />
              </FooterColumnTitle>

              <Link href="https://www.lyfta.com/about-us" target="_blank">
                <I18n i18nKey="welcome.footer.aboutUs" />
              </Link>
              <Link href="https://www.lyfta.com/privacy-policy" target="_blank">
                <I18n i18nKey="welcome.footer.privacyPolicy" />
              </Link>
              <Link
                href="https://www.lyfta.com/terms-and-conditions"
                target="_blank"
              >
                <I18n i18nKey="welcome.footer.termsConditions" />
              </Link>
              <Link href="https://filmmakers.lyfta.com" target="_blank">
                <I18n i18nKey="welcome.footer.filmakers" />
              </Link>
            </FooterColumn>
          </FooterGrid>
        </Footer>
        <Flex
          height="48px"
          fontSize="16px"
          pl={7}
          width="100%"
          alignItems="center"
          color="neutral_100"
          backgroundColor="black_900"
        >
          <I18n i18nKey="welcome.footer.copyright" />
        </Flex>
      </Container>
    )
  return <></>
}

export default Welcome
