import { Check2 } from '@lyfta/components-ui'
import takeWhile from 'lodash/takeWhile'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { Step, StepConnector, StepIcon, StepperContent } from './styles'

/* eslint-disable react/no-array-index-key */
function Stepper({ steps }) {
  const completed = useMemo(() => takeWhile(steps, ['completed', true]), [
    steps,
  ])

  return (
    <StepperContent>
      {steps &&
        steps.map((step, index) => (
          <React.Fragment key={index}>
            {index > 0 && <StepConnector active={!!completed?.[index - 1]} />}
            <Step active={step.active} completed={step.completed} key={index}>
              <StepIcon>{step.completed ? <Check2 /> : step.icon}</StepIcon>
            </Step>
          </React.Fragment>
        ))}
    </StepperContent>
  )
}

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
}

export default Stepper
