import { ButtonNew, Flex, styled, themeGet } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: 1,
  maxWidth: 550,
  alignSelf: 'center',
}))``

export const FAB = styled(ButtonNew).attrs(() => ({
  shadow: true,
}))`
  width: 50px;
  height: 50px;
  padding: 4px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3em;

  svg,
  path {
    fill: ${themeGet('colors.white')};
  }
`

export const FormContainer = styled(Flex)`
  > form {
    width: 100%;
  }
`

export const TeacherEmail = styled(Flex).attrs(() => ({
  pb: 2,
  borderBottom: '3px solid',
  borderColor: 'alto',
  alignItems: 'center',
  justifyContent: 'space-between',
}))``

export const Divider = styled(Flex).attrs(() => ({
  my: 2,
  borderBottom: '3px solid',
  borderColor: 'primary',
}))``
