import { getAllEntities, getEntity, getEntityStateField } from '../../utils'

export const getOrganizationLookups = getAllEntities(
  'lookupResults',
  'organizationLookups',
  'organizationLookups',
)
export const getCurrentOrganizationLookup = getEntity(
  'lookupResults',
  'organizationLookups',
  'organizationLookups',
)

export const getIsLoaded = getEntityStateField(
  'organizationLookups',
  'isLoaded',
)
export const getIsLoading = getEntityStateField(
  'organizationLookups',
  'isLoading',
)
export const getPaged = getEntityStateField('organizationLookups', 'paged')
