import { getCurrentMeetingInvite } from '@lyfta/components-data/src/Store/Selectors/meetingInvites'
import { I18n } from '@lyfta/components-i18n'
import paths from 'Constants/paths'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { joinInvitedSchool } from 'Store/Actions/signUp'

import {
  Container,
  Heading1,
  Heading2,
  NextButton,
  NextIcon,
} from '../../styles'
import { Actions } from '../SelectPlan/styles'

const ExistingOrganization = ({ history }) => {
  const dispatch = useDispatch()
  const { organization, id: meetingInviteId } = useSelector(
    getCurrentMeetingInvite,
  )

  const createNew = () => {
    history.push(paths.signUp.createAccount.organization)
  }

  const joinExisting = () => {
    dispatch(joinInvitedSchool())
  }

  useEffect(() => {
    if (meetingInviteId && isEmpty(organization)) {
      createNew()
    }
  }, [meetingInviteId])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.inviteAccept.organization.title" tag={Heading1} />
      <I18n
        i18nKey="signUp.inviteAccept.organization.subTitle"
        options={{ schoolName: organization?.name }}
        tag={Heading2}
      />

      <Actions>
        <NextButton
          disabled={isEmpty(meetingInviteId)}
          mr={2}
          onClick={createNew}
        >
          <I18n i18nKey="signUp.inviteAccept.organization.createNew" />
          <NextIcon />
        </NextButton>
        <NextButton disabled={isEmpty(meetingInviteId)} onClick={joinExisting}>
          <I18n i18nKey="signUp.inviteAccept.organization.continue" />
          <NextIcon />
        </NextButton>
      </Actions>
    </Container>
  )
}

ExistingOrganization.propTypes = {
  history: PropTypes.object.isRequired,
}
export default ExistingOrganization
