import { CoreStore } from '@lyfta/components-data'
import { connectRouter } from 'connected-react-router/immutable'
import { combineReducers } from 'redux'

import prismic from './prismic'
import signUp from './signUp'

export default history =>
  combineReducers({
    router: connectRouter(history),
    ...CoreStore.Reducers,
    signUp,
    prismic,
  })
