import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'

const Public = ({ route }) => renderRoutes(route.routes)

Public.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Public
