import { getPath } from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  INITIAL_STEPS_ORGANIZATION,
  INITIAL_STEPS_PARENT,
  STEP_BY_PATH,
} from 'Constants/signUp'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { getCompletedStepsRelativeTo } from 'Services/Stepper'

import Stepper from './Stepper'
import { Container, Content } from './styles'

/* eslint-disable no-unused-vars */
const SignUpLayout = ({ route }) => {
  const registrationPath = useSelector(getPath)

  const [steps, setSteps] = useState(
    registrationPath === 'organization' || registrationPath === null
      ? INITIAL_STEPS_ORGANIZATION
      : INITIAL_STEPS_PARENT,
  )

  const { pathname } = useLocation()

  useEffect(() => {
    const [, step] =
      STEP_BY_PATH.find(([path]) => {
        if (path instanceof RegExp) {
          return path.test(pathname)
        }
        return pathname.startsWith(path)
      }) ?? STEP_BY_PATH[0] // defaults to /set-password step

    setSteps(getCompletedStepsRelativeTo(step, registrationPath))
  }, [pathname])

  const firstPage = pathname === '/sign-up/organization-type'
  return (
    <Container>
      <Helmet />
      <Stepper steps={firstPage ? '' : steps} />
      <Content>{renderRoutes(route.routes)}</Content>
    </Container>
  )
}

SignUpLayout.propTypes = {
  route: PropTypes.object.isRequired,
}

export default SignUpLayout
