import { setOrganization } from '@lyfta/components-data/src/Store/Actions/viewer'
import { I18n } from '@lyfta/components-i18n'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Container, Heading1 } from '../../styles'
import OrganizationForm from './Form'
import { FormContainer } from './styles'

const OrganizationSetup = () => {
  const dispatch = useDispatch()
  const createSchool = useCallback(p => {
    if (p.enteredSchoolName) dispatch(setOrganization(p))
  }, [])

  useEffect(() => {
    document.title = 'Registration - Tell us about your school'
  }, [])

  return (
    <Container>
      <I18n i18nKey="signUp.organization.title" tag={Heading1} />

      <FormContainer>
        <OrganizationForm onSubmit={createSchool} />
      </FormContainer>
    </Container>
  )
}

export default OrganizationSetup
