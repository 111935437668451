import { BREAKPOINTS } from '@lyfta/components-theme'
import { styled } from '@lyfta/components-theme/src/styling/components'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { Flex } from '@lyfta/components-ui'

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  font-family: 'Poppins';
  > form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 80px;
    div {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      max-width: 550px;
      @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
        border-radius: 4px;
      }
    }
    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button {
      margin-bottom: 8px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height: 57px;

      @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
        border-radius: 4px;
      }
    }
  }
`
