import {
  Key,
  LyftaLogoSymbolBorder,
  School2,
  User,
  Users,
} from '@lyfta/components-ui'
import React from 'react'

import paths from './paths'

export const STEP_TYPE = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
  FOURTH: 3,
}

export const STEP_BY_PATH = [
  [paths.signUp.createAccount.organizationType, STEP_TYPE.FIRST],

  // TEACHER PATH
  [paths.signUp.createAccount.userType, STEP_TYPE.FIRST],
  [paths.signUp.createAccount.organization, STEP_TYPE.SECOND],
  [paths.signUp.createAccount.createUserAccount, STEP_TYPE.THIRD],
  [paths.signUp.createAccount.settingAccount, STEP_TYPE.THIRD],
  [paths.signUp.createAccount.userCount, STEP_TYPE.THIRD],

  // PARENT PATH
  // [paths.signUp.createAccount.createParentAccount, STEP_TYPE.FIRST],
  [paths.signUp.createAccount.parentResources, STEP_TYPE.SECOND],
  [paths.signUp.createAccount.childrenAccount, STEP_TYPE.THIRD],

  // END OF PATH
  [paths.signUp.createAccount.done, STEP_TYPE.FOURTH],

  [paths.signUp.createAccount.plan, STEP_TYPE.THIRD],
  [paths.signUp.createAccount.confirmSkip, STEP_TYPE.THIRD],
  [/meetings\/\d+\/register/, STEP_TYPE.THIRD],
  [/meetings\/\d+\/attendance\/\d+\/book/, STEP_TYPE.FOURTH],
  [/meetings\/\d+\/attendance\/\d+\/invite/, STEP_TYPE.FOURTH],
  [/webinar\/\d+\/register/, STEP_TYPE.FOURTH],
  [paths.signUp.createAccount.complete, STEP_TYPE.FOURTH],
  [paths.signUp.createAccount.done, STEP_TYPE.FOURTH],
  [paths.signUp.createAccount.invitesSent, STEP_TYPE.FOURTH],
]

export const INITIAL_STEPS_ORGANIZATION = [
  {
    icon: <User />,
    active: false,
    completed: false,
  },
  {
    icon: <School2 />,
    active: false,
    completed: false,
  },
  {
    icon: <Key />,
    active: false,
    completed: false,
  },
  {
    icon: <LyftaLogoSymbolBorder />,
    active: false,
    completed: false,
  },
]
export const INITIAL_STEPS_PARENT = [
  {
    icon: <Key />,
    active: false,
    completed: false,
  },
  {
    icon: <User />,
    active: false,
    completed: false,
  },
  {
    icon: <Users />,
    active: false,
    completed: false,
  },
  {
    icon: <LyftaLogoSymbolBorder />,
    active: false,
    completed: false,
  },
]
