import { BREAKPOINTS, css, styled, themeGet } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { Flex, Text } from '@lyfta/components-ui'

export const TypesContainer = styled(Flex).attrs(() => ({ my: [2, 4] }))`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8px;
  row-gap: 8px;

  @media only screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const UserTypeContainer = styled(Flex).attrs(() => ({
  alignItems: 'center',
  flexDirection: 'column',
  height: [130, 200],
  width: [130, 200],
  justifyContent: 'center',
  p: [2, 4],
}))`
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid ${themeGet('colors.primary')};
  color: ${themeGet('colors.primary')};
  background-color: ${themeGet('colors.white')};
  font-weight: 700;
  transition: all 0.17s;
  text-align: center;
  font-size: 12px;
  line-height: 1.25;

  ${({ active }) =>
    active &&
    css`
      color: ${themeGet('colors.white')};
      background-color: ${themeGet('colors.primary')};
      box-shadow: #00000050 0 5px 10px;
    `};
  &:hover {
    color: #1ed3c6 !important;
    background-color: ${themeGet('colors.primary')};
  }

  @media screen and (min-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    font-size: 14px;
  }
`

export const UserTypeIcon = styled(Flex).attrs(() => ({
  flex: '1 1 100%',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))`
  font-size: 5em;
`
export const IconContainer = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  svg,
  path {
    fill: ${themeGet('colors.white')};
  }
`

export const WhiteTitle = styled(Text).attrs(() => ({}))`
  color: ${themeGet('colors.white')};
`

export const SeparatorContainer = styled(Flex).attrs(() => ({
  width: '100%',
}))`
  height: 3rem;
`

export const Link = styled(Flex).attrs(() => ({}))`
  font-size: 14px;
  text-align: center;
  display: block;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: ${themeGet('colors.primary')};
`
export const CheckboxLabel = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  color: ${themeGet('colors.primary')};
  align-items: left;
`

export const ParentOtherText = styled(Flex).attrs(() => ({
  mx: 3,
  my: 2,
  fontSize: [2, 3],
}))`
  max-width: 850px;
`
