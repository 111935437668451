import { PRISMIC_TYPES } from 'Constants/prismic'
import { filter, find, map } from 'lodash'
import { nanoid } from 'nanoid'
import { createSelector } from 'reselect'

export const getState = state => state.prismic

export const getPrismicLoading = createSelector(
  getState,
  state => state.isLoading,
)

export const getPrismicLoaded = createSelector(
  getState,
  state => state.isLoaded,
)

export const getPrismicFAQ = createSelector(getState, state => {
  return filter(state.data, ['type', PRISMIC_TYPES.FAQ]).map(item => ({
    ...item.data,
    id: nanoid(),
  }))
})

export const getPrismicRegistrationHomePage = createSelector(
  getState,
  state =>
    find(state.data, { type: PRISMIC_TYPES.REGISTRATION_HOME_PAGE })?.data,
)

export const getHomePageStatistics = createSelector(
  getPrismicRegistrationHomePage,
  prismicHomePage =>
    prismicHomePage?.body &&
    map(filter(prismicHomePage?.body, ['slice_type', 'statistics']), f => ({
      ...f,
      items: map(f.items, i => ({ ...i, id: nanoid() })),
    }))[0],
)

export const getHomePageFeatures = createSelector(
  getPrismicRegistrationHomePage,
  prismicHomePage =>
    prismicHomePage?.body &&
    map(filter(prismicHomePage?.body, ['slice_type', 'feature']), f => ({
      ...f,
      id: nanoid(),
    })),
)
