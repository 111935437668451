import {
  SEND_INVITES,
  sendInvite,
  updateMeetingInvite,
} from '@lyfta/components-data/src/Store/Actions/meetingInvites'
import {
  loadOrganization,
  updateOrganization,
} from '@lyfta/components-data/src/Store/Actions/organizations'
import {
  updateUser,
  validateEmail,
} from '@lyfta/components-data/src/Store/Actions/users'
import {
  SET_EMAIL,
  SET_ORGANIZATION,
  SET_USER_TYPE,
  loadViewer,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getCurrentMeetingInvite } from '@lyfta/components-data/src/Store/Selectors/meetingInvites'
import { getCurrentOrganization } from '@lyfta/components-data/src/Store/Selectors/organizations'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { push } from 'connected-react-router'
import paths from 'Constants/paths'
import { all, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import {
  ACCEPT_INVITE,
  CONFIRM_SKIP,
  JOIN_INVITED_SCHOOL,
  SELECT_PLAN,
  SET_ORGANIZATION_TEACHER_COUNT,
  setEmail,
} from '../Actions/signUp'

function* checkEmail({ email, redirect }) {
  const response = yield yield put(validateEmail(email))
  if (response.ok) {
    const {
      payload: {
        data: { exists },
      },
    } = response
    if (exists) {
      return yield put(push(paths.signUp.createAccount.login))
    }
  }
  if (redirect) return yield put(push(paths.signUp.createAccount.userType))
  return ''
}
function* checkType() {
  return yield put(push(paths.signUp.createAccount.organization))
}

function* redirectAfterOrganizationSetup() {
  return yield put(push(paths.signUp.createAccount.createUserAccount))
}

function* redirectToWebinar() {
  const viewerRecord = yield select(getViewer)

  const {
    organization: { id },
  } = viewerRecord

  const response = yield yield put(loadOrganization(id))

  if (response.ok) {
    const organization = yield select(getCurrentOrganization)

    if (organization.licensings) {
      const { license } = organization.licensings[0]

      if (license) {
        const { suggestedMeeting } = license

        if (suggestedMeeting) {
          return yield put(
            push(paths.webinarBooking.register(suggestedMeeting.id)),
          )
        }
      }
    }
  }
  return yield put(push(paths.signUp.createAccount.complete))
}

function* redirectToConfirmSkip() {
  yield put(push(paths.signUp.createAccount.confirmSkip))
}

function* updateTeacherCount({ count }) {
  const {
    organization: { id },
  } = yield select(getViewer)

  const response = yield yield put(
    updateOrganization({ id, teachersLimit: count }),
  )

  if (response.ok) {
    yield put(push(paths.signUp.createAccount.plan))
  }
}

function* sendInvites({ payload }) {
  const responses = yield all(payload.map(body => putResolve(sendInvite(body))))

  if (responses.every(response => response.ok)) {
    yield put(push(paths.signUp.createAccount.invitesSent))
  }
}

function* acceptInvite({
  meetingAttendee: {
    meeting: { id: meetingId },
    email,
    firstName,
    lastName,
    id,
  },
}) {
  yield put(setEmail(email, firstName, lastName))
  yield put(updateMeetingInvite({ meetingId, id, status: 'converted' }))
  yield put(push(paths.signUp.createAccount.createUserAccount))
}

function* joinInvitedSchool() {
  const { id } = yield select(getViewer)
  const {
    organization: { id: organizationId },
  } = yield select(getCurrentMeetingInvite)

  const response = yield yield put(updateUser({ id, organizationId }))

  if (response.ok) {
    yield put(loadViewer())
    yield redirectToWebinar()
  }
}

export default function* root() {
  yield all([
    takeLatest(SET_EMAIL, checkEmail),
    takeLatest(SET_USER_TYPE, checkType),
    takeLatest(SET_ORGANIZATION, redirectAfterOrganizationSetup),
    takeLatest(SET_ORGANIZATION_TEACHER_COUNT, updateTeacherCount),
    takeLatest(SELECT_PLAN, redirectToWebinar),
    takeLatest(CONFIRM_SKIP, redirectToConfirmSkip),
    takeLatest(SEND_INVITES, sendInvites),
    takeLatest(ACCEPT_INVITE, acceptInvite),
    takeLatest(JOIN_INVITED_SCHOOL, joinInvitedSchool),
  ])
}
