import PropTypes from 'prop-types'
import React from 'react'

import { Span } from '../../BaseComponents'
import { LocationButton, LocationCheckedIcon } from './style'

export const ButtonWithTick = ({
  onClick,
  children,
  label,
  active,
  ...props
}) => {
  return (
    <LocationButton
      active={active}
      color="primary"
      inverse={!active}
      mr={[0, 4]}
      onClick={() => onClick()}
      {...props}
    >
      <>
        <LocationCheckedIcon
          active={active}
          color="primary"
          key="loc-gb-icon"
        />
        <Span key="loc-gb-text" ml={4}>
          {label}
        </Span>
      </>
    </LocationButton>
  )
}

ButtonWithTick.defaultProps = {
  onClick: null,
}

ButtonWithTick.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func, PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
