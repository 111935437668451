import {
  loadTeacher,
  requestUpdateUser,
} from '@lyfta/components-data/src/Store/Actions/users'
import {
  getCurrentTeacher,
  getTeacherUserName,
} from '@lyfta/components-data/src/Store/Selectors/users'
import { I18n } from '@lyfta/components-i18n'
import { Constraints, LyftaForm } from '@lyfta/components-ui'
import React, { useEffect } from 'react'

import { Container, Heading1 } from '../../styles'
import fieldset from './support/fields'

const SetUserType = () => {
  useEffect(() => {
    document.title = 'Registration - Setting your account'
  }, [])

  return (
    <Container minWidth={['unset', 500]}>
      <I18n i18nKey="signUp.settingAccount.title" tag={Heading1} />

      <LyftaForm
        constraints={{
          ...Constraints.heardAboutConstraint,
        }}
        createAction={requestUpdateUser}
        fields={fieldset}
        loadAction={loadTeacher}
        modelName="teachers"
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        noWrap
        recordNameSelector={getTeacherUserName}
        recordSelector={getCurrentTeacher}
        withoutHeader
        wrapperProps={{ flex: 20, flexDirection: 'column', width: '100%' }}
      />
    </Container>
  )
}

export default SetUserType
