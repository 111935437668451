import { Constraints } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import validate from 'validate.js'

import formFields from './form'

const OrganizationForm = ({ onSubmit }) => {
  const validateForm = values =>
    validate(values, {
      ...Constraints.countryConstraint,
    })
  return (
    <Form
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      // mutators={{ handleEmailEntry }}
      render={formFields}
      validate={validateForm}
      onSubmit={onSubmit}
    />
  )
}
OrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
export default OrganizationForm
