import * as React from 'react'

function SvgCalendar(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 103 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.06 11.548h-3.888l-1.11 4.065c-1.019 3.694-4.536 6.465-8.239 6.465-2.036 0-3.98-.831-5.09-2.401-1.204-1.57-1.574-3.695-1.019-5.727l.648-2.402H49.774l-1.111 4.065c-.926 3.694-4.536 6.465-8.238 6.465-2.036 0-3.98-.831-5.09-2.401-1.204-1.57-1.574-3.695-1.019-5.727l.648-2.402H30.89c-6.201 0-12.403 4.803-13.977 10.992L5.437 64.752c-.926 3.325-.37 6.65 1.573 9.052 1.852 2.401 4.72 3.787 7.96 3.787h5.647v2.494c0 6.558 5.368 11.915 11.94 11.915H81.06C87.63 92 93 86.643 93 80.085V23.464c0-6.558-5.369-11.916-11.94-11.916zM15.063 70.386c-.74 0-1.666-.185-2.221-.924-.463-.646-.648-1.662-.278-2.863l9.534-35.284H79.67l-8.978 33.437c-.833 3.048-4.073 5.634-7.035 5.634H15.063zm70.625-34.453v44.152h.092c0 2.586-2.129 4.71-4.72 4.71H32.557c-2.591 0-4.72-2.124-4.72-4.71V77.59h35.636c6.201 0 12.403-4.803 13.977-10.992l8.238-30.666zm-41.795 5.49a3.75 3.75 0 014.51-2.64c1.974.566 3.196 2.545 2.632 4.524l-1.034 3.863h3.665c2.068 0 3.666 1.696 3.76 3.675 0 2.073-1.598 3.675-3.666 3.675h-5.638l-1.504 5.747A3.696 3.696 0 0143.047 63c-.282 0-.658 0-.94-.094-1.974-.566-3.195-2.544-2.631-4.523l.94-3.863H36.75c-2.067 0-3.665-1.602-3.665-3.675 0-2.073 1.598-3.675 3.665-3.675h5.639zM44.148 5C46.264 5 47.552 6.79 47 9.052l-1.288 4.993c-.552 2.262-2.76 4.052-4.967 4.052-2.115 0-3.403-1.885-2.851-4.052l1.288-4.993C39.733 6.79 41.94 5 44.148 5zm27.178 0c2.095 0 3.37 1.79 2.824 4.052l-1.275 4.993c-.547 2.262-2.733 4.052-4.919 4.052-2.186 0-3.461-1.885-2.915-4.052l1.366-4.993C66.954 6.79 69.14 5 71.327 5z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgCalendar
