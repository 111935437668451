import { createAsyncAction } from '@lyfta/components-data'

export const LOAD_PRISMIC = createAsyncAction('prismic/LOAD')

export const requestLoadPrismic = predicate => ({
  type: LOAD_PRISMIC.REQUEST,
  payload: { predicate },
})

export const loadPrismicSuccess = data => ({
  type: LOAD_PRISMIC.SUCCESS,
  payload: { data },
})

export const loadPrismicFailure = error => ({
  type: LOAD_PRISMIC.FAILURE,
  payload: { error },
})
